import Divider from '../../../components/divider/Divider'
import { useContext, useEffect, useState } from 'react'
import { AccountDetailsUI } from '../../../composites/accountDetailsUI/AccountDetailsUI'
import { AddressUI } from '../../../composites/addressUI/AddressUI'
import { AuditTrailUI } from '../../../composites/auditTrailUI/AuditTrailUI'
import { HouseholdUI } from '../../../composites/householdUI/HouseholdUI'
import { COLORS } from '../../../theme/Colors'
import {
  AccessWrapper,
  AIWRootContainer,
  DividerWrapper2,
  LineBreakWrapper,
  StyledArticleContent,
} from './styles'
import { ApprovalContext } from '../../../services/approvalContext/ApprovalContext'
import { ApprovalUI } from '../../../composites/approvalUI/ApprovalUI'
import { Investment } from '../../../composites/investment/Investment'
import { AuthContext } from '../../../services/AuthContext'
import {
  WDApplicationHeading,
  WDInputLabel,
} from '../../../components/ui/WDTypography'
import { AccountRestrictionsUI } from '../../../composites/AccountRestrictionsUI/AccountRestrictionsUI'
import { AccountRestrictionsAuditTrailUI } from '../../../composites/accountRestrictionsAuditTrailUI/accountRestrictionsAuditTrailUI'
import { AccountRestrictionsContext } from '../../../services/accountRestrictionsContext/AccountRestrictionsContext '
import { AccountRoles } from '../../../composites/accountRoles/AccountRoles'
import AccountLoanDetailsUI from '../../../composites/accountLoanDetailsUI/AccountLoanDetailsUI'
import AccountRestrictionReports from '../../../composites/accountRestrictionReports/AccountRestrictionReports'
import { AiwContext } from '../../../services/aiwContext/AiwContext'
import { RootContainer } from '../headerContent/styles'
import { AccountLoanDetailsContext } from '../../../services/accountLoanDetailsContext/AccountLoanDetailsContext'
import { Beneficiary } from '../../../composites/beneficiary/Beneficiary'

const ArticleContent = () => {
  const electron = useContext(AiwContext)
  const approval = useContext(ApprovalContext)
  const accountLoanDetailContext = useContext(AccountLoanDetailsContext)
  const accountRestrictionsCtx = useContext(AccountRestrictionsContext)
  const authCtx = useContext(AuthContext)
  const [userRolesError, setUserRolesError] = useState(undefined)

  // useEffect(() => {
  //   setTimeout(() => {
  //     setUserRolesError(
  //       "You don't have access to the application.\nPlease reach out to access management team."
  //     )
  //   }, 3000)
  // }, [])


  return (
    <>
      {electron?.inputValue?.input === undefined && !authCtx?.userRoles?.includes('AM-APPROVER')  ?
        <AIWRootContainer>
          <AccessWrapper>
            <WDApplicationHeading>
              {"The context's associated account has expired. Please close the application and reopen it after setting a new account context."}
            </WDApplicationHeading>
          </AccessWrapper>
        </AIWRootContainer>
        : (
          <StyledArticleContent>
            <DividerWrapper2>
              <Divider
                bgColor={COLORS.Background.Border}
                horizontal={true}
                thickness="1px"
              />
            </DividerWrapper2>

            {authCtx?.userRoles?.length === 0 && (
              <>
                {/* <ApprovalUI /> */}
                <AccountDetailsUI />
                {authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ?
                  <AccountLoanDetailsUI /> : accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith !== 'None' ? <AccountLoanDetailsUI /> : ''
                }
                <AddressUI />
                <AccountRoles />
                <HouseholdUI />
                <Investment />
                <Beneficiary />
                {/* <AuditTrailUI /> */}
              </>
            )}

            {(approval?.approver) && (
              <>
                <ApprovalUI />
                <AccountDetailsUI />
                {authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ?
                  <AccountLoanDetailsUI /> : accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith !== 'None' ? <AccountLoanDetailsUI /> : ''
                }
                <AddressUI />
                <AccountRoles />
                <HouseholdUI />
                <Investment />
                <Beneficiary />
                <AuditTrailUI />
              </>
            )}
            {approval?.approverAndRestriction && (
              <>
                <ApprovalUI />
                <AccountDetailsUI />
                {authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ?
                  <AccountLoanDetailsUI /> : accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith !== 'None' ? <AccountLoanDetailsUI /> : ''
                }
                <AddressUI />
                <AccountRoles />
                <HouseholdUI />
                <Investment />
                <Beneficiary />
                <AuditTrailUI />
                <AccountRestrictionsUI />
                <AccountRestrictionsAuditTrailUI />
              </>
            )}

            {approval?.admin && !authCtx?.userRoles?.includes('AM-RESTRICTIONMANAGEMENT') && (
              <>
                <ApprovalUI />
                <AccountDetailsUI />
                {authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ?
                  <AccountLoanDetailsUI /> : accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith !== 'None' ? <AccountLoanDetailsUI /> : ''
                }
                <AddressUI />
                <AccountRoles />
                <HouseholdUI />
                <Investment />
                <Beneficiary />
                <AuditTrailUI />
                {/* <AccountRestrictionsUI />
          <AccountRestrictionsAuditTrailUI /> */}
              </>
            )}

            {approval?.admin && authCtx?.userRoles?.includes('AM-RESTRICTIONMANAGEMENT') && (
              <>
                <ApprovalUI />
                <AccountDetailsUI />
                {authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ?
                  <AccountLoanDetailsUI /> : accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith !== 'None' ? <AccountLoanDetailsUI /> : ''
                }
                <AddressUI />
                <AccountRoles />
                <HouseholdUI />
                <Investment />
                <Beneficiary />
                <AuditTrailUI />
                <AccountRestrictionsUI />
                <AccountRestrictionsAuditTrailUI />
                <AccountRestrictionReports />
              </>
            )}

            {approval?.requesterAndRestriction && (
              <>
                <AccountDetailsUI />
                {authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ?
                  <AccountLoanDetailsUI /> : accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith !== 'None' ? <AccountLoanDetailsUI /> : ''
                }
                <AddressUI />
                <AccountRoles />
                <HouseholdUI />
                <Investment />
                <Beneficiary />
                <AuditTrailUI />
                <AccountRestrictionsUI />
                <AccountRestrictionsAuditTrailUI />
                <AccountRestrictionReports />
              </>
            )}


            {approval?.requester && (
              <>
                <AccountDetailsUI />
                {accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') &&
                  <AccountLoanDetailsUI />
                }
                <AddressUI />
                <AccountRoles />
                <HouseholdUI />
                <Investment />
                <Beneficiary />
                <AuditTrailUI />
              </>
            )}
            {(approval?.restrictionUser) && (
              <>
                <AccountDetailsUI />
                {accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') &&
                  <AccountLoanDetailsUI />
                }
                <AddressUI />
                <AccountRoles />
                <HouseholdUI />
                <Investment />
                <Beneficiary />
                {/* <AuditTrailUI /> */}
                <AccountRestrictionsUI />
                <AccountRestrictionsAuditTrailUI />
                {<AccountRestrictionReports />}
              </>
            )}
          </StyledArticleContent>
        )
      }
    </>
  )
}

export default ArticleContent
