export const SvgEye = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.6133 7.73341C13.2667 4.60675 10.7333 2.66675 7.99998 2.66675C5.26665 2.66675 2.73332 4.60675 1.38665 7.73341C1.34994 7.81753 1.33099 7.90831 1.33099 8.00008C1.33099 8.09185 1.34994 8.18264 1.38665 8.26675C2.73332 11.3934 5.26665 13.3334 7.99998 13.3334C10.7333 13.3334 13.2667 11.3934 14.6133 8.26675C14.65 8.18264 14.669 8.09185 14.669 8.00008C14.669 7.90831 14.65 7.81753 14.6133 7.73341ZM7.99998 12.0001C5.88665 12.0001 3.88665 10.4734 2.73332 8.00008C3.88665 5.52675 5.88665 4.00008 7.99998 4.00008C10.1133 4.00008 12.1133 5.52675 13.2667 8.00008C12.1133 10.4734 10.1133 12.0001 7.99998 12.0001ZM7.99998 5.33341C7.47257 5.33341 6.95699 5.48981 6.51846 5.78283C6.07993 6.07585 5.73814 6.49232 5.5363 6.97959C5.33447 7.46686 5.28166 8.00304 5.38456 8.52032C5.48745 9.0376 5.74143 9.51276 6.11437 9.8857C6.48731 10.2586 6.96246 10.5126 7.47974 10.6155C7.99703 10.7184 8.5332 10.6656 9.02047 10.4638C9.50774 10.2619 9.92422 9.92013 10.2172 9.4816C10.5103 9.04307 10.6667 8.5275 10.6667 8.00008C10.6667 7.29284 10.3857 6.61456 9.8856 6.11446C9.3855 5.61437 8.70723 5.33341 7.99998 5.33341ZM7.99998 9.33341C7.73628 9.33341 7.47849 9.25522 7.25922 9.10871C7.03996 8.9622 6.86906 8.75396 6.76814 8.51033C6.66723 8.26669 6.64082 7.9986 6.69227 7.73996C6.74372 7.48132 6.8707 7.24374 7.05717 7.05727C7.24364 6.8708 7.48122 6.74381 7.73986 6.69237C7.9985 6.64092 8.26659 6.66733 8.51023 6.76824C8.75386 6.86916 8.9621 7.04006 9.10861 7.25932C9.25512 7.47859 9.33332 7.73637 9.33332 8.00008C9.33332 8.3537 9.19284 8.69284 8.94279 8.94289C8.69274 9.19294 8.35361 9.33341 7.99998 9.33341Z"
        fill="#475569"
      />
    </svg>
  )
}
