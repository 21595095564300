import React from "react";
import SelectDropdown from "../../components/selectDropdown/SelectDropdown";
import { WDNewProfileSidebarMenu } from "../../components/ui/WDTypography";
import { MandatoryLabel } from "../inputField/styles";
import { DropdownFieldProps } from "./IDropdownField";

import {
  InputWrapper,
  LabelWrap,
  DropdownWrap,
} from "./styles";

export const DropdownField = ({
  options,
  onChange,
  defaultValue,
  name,
  label,
  disabled,
  backgroundColor,
  borderColor,
  value,
  lh,
  mandatory
}: DropdownFieldProps) => {
  return (
    <InputWrapper>
      <LabelWrap>
      <WDNewProfileSidebarMenu>{label}</WDNewProfileSidebarMenu>
      {mandatory && <MandatoryLabel>{' *'}</MandatoryLabel>}
      </LabelWrap>
      <DropdownWrap>
        <SelectDropdown
          isDisabled={disabled}
          options={options}
          onChange={onChange}
          defaultValue={defaultValue}
          value={value}
          fw={400}
          fs={"16px"}
          lh={lh ?? "24px"}
          ff={"SourceSansPro-Regular"}
          borderColor={borderColor}
          borderRadius={"8px"}
          selectedValueColor={"#475569"}
          width={"100%"}
          name={name}
          backgroundColor={backgroundColor}
        />
      </DropdownWrap>
    </InputWrapper>
  );
};

/* 
    This composite is a responsive-reusable dropdown field.
   Flex box is used here, contains a label component and a dropdown component,
   which together makes it as a composite component.
*/
