import { MandatoryLabel } from "../../composites/inputField/styles";
import { LabelWrap } from "../telephoneInput/styles";
import { WDNewProfileSidebarMenu } from "../ui/WDTypography";
import { TextboxProps } from "./ITextbox";
import { Main, StyledTextArea, Wrapper } from "./styles";

export const Textbox = ({
  backgroundColor,
  disabled,
  type,
  placeholder,
  required,
  onChange,
  onBlur,
  ref,
  defaultValue,
  name,
  value,
  id,
  max,
  borderColor,
}: TextboxProps) => {
  return (
    <Wrapper
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange?.(e.target.value)
      }
    >
      {type!=='textarea' && <Main
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        required={required}
        onBlur={onBlur}
        ref={ref}
        max={max}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
      />}
      {type==='textarea' && 
      <StyledTextArea
      name={name}
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        required={required}
        onBlur={onBlur}
        max={max}
        borderColor={borderColor}
        backgroundColor={backgroundColor}></StyledTextArea>
      }
    </Wrapper>
  );
};
