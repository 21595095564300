import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

interface InvestmentProps {
  borderShadow?: boolean
}

const borderShadowProperty = '0px 2px 6px -2px rgba(0, 36, 93, 0.3)'

export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex: 0 1 100%;
  width: 100%;
  padding: 8px 24px;
  margin-bottom: 16px;
  margin-left: 24px;
  background: ${COLORS.Background.Primary};
  /* neutral/border-weak */

  border: 1px solid #e2e8f0;
  /* Elevation/Elevation-low */

  box-shadow: ${(AddressProps: InvestmentProps) =>
    AddressProps.borderShadow ? borderShadowProperty : null};
  border-radius: 8px;
`
export const HeaderRow = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
  max-height: 48px;
  gap: 16px;
  margin: 0px;
  cursor: pointer;
`

export const TitleIconWrap = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 10px;
`

export const TopRow = styled.div`
  display: flex;
  max-height: 48px;
  padding-bottom: 8px;
  width: 100%;
  margin: 0px;
  cursor: pointer;
`
export const TertiaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 92px;
  min-height: 32px;
  align-items: center;
  justify-content: flex-end;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
export const DividerWrapper2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top:16px;
`
export const Row = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  gap: 24px;
  margin: 4px 0px;
`
export const Row2 = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  margin:0px 0px 16px 139px;
`
export const Row3 = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  gap: 16px;
  margin: 8px 0px;
`
export const Row4 = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  gap: 8px;
  margin: 8px 0px;
`
export const EditWrapper = styled.div`
  padding-left:10px
`

export const InputFieldLiquidity=styled.div`
margin-left:220px;
`

export const FlexItem = styled.div`
  flex: 0 1 50%;
  width: 50%;
`
export const FlexItem2 = styled.div`
  flex: 0 1 23.5%;
  width: 23.5%;
`
export const LabelWrapper = styled.div`
  display: flex;
  margin-top: 4px;
`;
export const LabelWrapper2 = styled.div`
  display: flex;
  margin-top: 12px;
`;
export const InnerLabelWrapper=styled.div`
margin-top:-5px;
margin-left:4px;
`
export const CheckboxWrapper=styled.div`
margin-right:4px;
`
export const SubHeadingWrapper=styled.div`
margin-top:16px;`

export const LabelWrap = styled.div`
  padding: 4px 0px;
`

export const CompactWrapper = styled.div`
  flex: 0 1 50%;
  max-width: 80px;
   div{
    :nth-child(3) {
      height: 32px;
      border-radius: 4px;
    }
      }
`;
export const InputFieldInlineWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  align-items: center;
  margin-top:8px;
`;
export const DoubleLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top:32px;
`;
export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 84px;
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
`
export const SecondaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 110px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const PrimaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 6.0648px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const TableColumnWrapper=styled.div`
margin-right:40px;
`
export const ExperienceWrapper=styled.div`
display: flex;
align-items: center;
gap:62px;
`
export const ExperienceLabelWrapper=styled.div`
display:flex;
min-width:95px;
`
export const ExperienceRadioWrapper=styled.div`
display: flex;
flex-direction: row;
gap:38px;
`
export const IconWrapper = styled.div`
margin-top:4px;
`
export const ValueWrapper = styled.div`
margin-top:8px;
`
export const TextboxWrapper=styled.div`
 max-width: 91px;
 input{
  padding: 4px 8px;
  border-radius: 4px;
 }
`
export const TextboxWrapper2=styled.div`
 max-width: 80px;
 margin-left: 88px;
 input{
  display:flex;
  padding: 4px 8px;
  border-radius: 4px;
  justify-content: center;
  height:32px;
 }
`

export const StyledTableContainer = styled.div`
  width: 100%;
  /* padding: 24px; */
  padding: 0px 0px 16px;
  background: '#FFFFFF';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* border: 1px solid #cbd5e1;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px; */
  border-top: none;
  table {
    tbody {
      tr {
        text-align: left;
        td {
          padding: 8px;
          font-family: SourceSansPro-Regular !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #0f172a;
          :nth-child(1) {
            padding: 8px;
            font-family: SourceSansPro-Regular !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 16px !important;
          }
        }
      }
    }

    thead {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #0f172a;
      tr {
        th {
          padding: 4px 0px 4px 8px;
          font-family: SourceSansPro-Bold !important;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #0f172a;
          width: 20%;
        }
      }
    }
  }
`

export const Wrapper2 = styled.div`
  display: flex;
  align-items: center;
  gap: 5.33px;
  width: 100%;
  height: 100%;
`

export const ModalWrapper = styled.div`
  position: fixed;
  /* overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  } */
  z-index: 1;
  // overflow-y: scroll;
  display: flex;
  height:100%;
  position: fixed;
  padding-top: 1.5rem;
  top: 0rem;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  /* padding-bottom: 30rem; */
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 116, 139, 0.8);
  width: 100%;
  // margin: 0 -5%;
  `
  export const InvestmentPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  height: 530px !important;
  position: absolute;
  flex: 0 1 100%;
  width: 62.77%;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  margin-top: 1rem;
  background: ${COLORS.Background.Primary};
  border: 1px solid #e2e8f0;
  box-shadow: ${(ContactUIProps: InvestmentProps) =>
    ContactUIProps.borderShadow ? borderShadowProperty : null};
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`
export const BoxRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  /* max-height: 6.5rem; */
  margin: 8px 0px;
  padding: 0px 0px;
`
export const NotesWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 100px;
  gap: 16px;
  margin: 8px 0px;
`
export const CloseWrapper = styled.div`
  cursor: pointer;
`