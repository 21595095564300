import styled from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: center;
  width: 100%;
  flex: 0 1 100%;
`;

export const DropdownWrap = styled.div`
  font-family: 'SourceSansPro-Regular';
  /* max-height: 3rem; */
  // div{
  //   position: inherit;
  // }
  div{
  :nth-child(3){
    div{
      font-family: 'SourceSansPro-Regular' !important;
    }
  }
  }
`;

export const LabelWrap = styled.div`
  padding: 4px 0px;
  display: flex;
`;
