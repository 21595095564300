import React, { createContext, useContext, useEffect, useState } from 'react'
import { beneficiaryDetailsSubmissionMethod, fetchBeneficiaryDetails } from '../../composites/beneficiary/BeneficiaryHttp'
import { ApprovalContext } from '../approvalContext/ApprovalContext'
import { AuthContext } from '../AuthContext'
import useAxiosProtected from '../http/useAxiosProtected'

interface BeneficiaryContextInterface {
  beneficiaryData: BeneficiaryUpdate[] | undefined
  beneficiaryCollapse: boolean
  setBeneficiaryCollapse: SetBeneficiaryCollapse
  beneficiarytDetailsUpdatedData: BeneficiaryDetailsUpdatedValues | undefined
  beneficiaryDetailSubmissionPayload: BeneficiaryDetailsUpdatedValues | undefined
  setBeneficiaryDetailsSubmissionPayload: SetBeneficiaryDetailsUpdateData
  submissionPopUp: boolean
  setSubmissionPopUp: SetBeneficiaryCollapse
  submissionTableData: TableData[] | undefined
  setSubmissionTableData: SetSubmissionTableData
  setPrimaryBeneficiaryUpdatedData: SetSubmissionTableData
  setContingentUpdatedData: SetSubmissionTableData
  primaryBeneficiaryUpdatedData: TableData[] | undefined
  contingentUpdatedData: TableData[] | undefined
  retrieveBeneficiaryDetails: LoadUI
}

interface LoadUI {
  (): void
}

interface SetBeneficiaryDetailsUpdateData {
  (value: BeneficiaryDetailsUpdatedValues): void | BeneficiaryDetailsUpdatedValues
}

interface SetBeneficiaryCollapse {
  (value: boolean): void | boolean
}
interface SetSubmissionTableData {
  (value: TableData[]): void | TableData[]
}

export interface TableData {
  field?: string | undefined
  oldValue?: string | undefined
  newValue?: string | undefined
}
interface SetSubmissionPopUp {
  (value: boolean): void | boolean
}

export interface BeneficiaryDetailsUpdatedValues {
  beneficiaryDetails?: BeneficiaryUpdate[]
  updateBy?: string
  notes?: string
}

export interface BeneficiaryUpdate {
  accountId?:string
  partyId?: string
  beneficiaryInformationId?:string
  type?: string
  designation?: string
  firstName?: string
  middleName?: string
  lastName?: string
  fullName?: string
  ssn?: string
  tin?: string
  ssnOrTin?:string
  dateOfBirth?: string
  relationship?: string
  benefits?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  country?: string
  zipCode?: string
  rightOfRepresentation?: string
  totalBenefits?: string
}
export interface OldBeneficiaryUpdate {
  partyId?: string
  firstName?: string
  middleName?: string
  lastName?: string
  fullName?: string
  ssnOrTin?: string
  dateOfBirth?: string
  relationship?: string
  benefits?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  country?: string
  zipCode?: string
  rightOfRepresentation?: string
}

export const BeneficiaryContext = createContext<
  BeneficiaryContextInterface | undefined | null
>(undefined)

interface BeneficiaryCtxProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const BeneficiaryContextProvider = ({
  children,
}: BeneficiaryCtxProviderProps) => {
  const [submissionPopUp, setSubmissionPopUp] = useState(false)
  const [beneficiaryCollapse, setBeneficiaryCollapse] = useState(false)
  const [submissionTableData, setSubmissionTableData] = useState<TableData[]>()
  const [primaryBeneficiaryUpdatedData, setPrimaryBeneficiaryUpdatedData] = useState<TableData[]>()
  const [contingentUpdatedData, setContingentUpdatedData] = useState<TableData[]>()
  const [beneficiaryData, setBeneficiaryData] = useState<BeneficiaryUpdate[] | undefined>()
  const [beneficiaryDetailSubmissionPayload, setBeneficiaryDetailsSubmissionPayload] =
    useState<BeneficiaryDetailsUpdatedValues>()
  const [beneficiarytDetailsUpdatedData, setBeneficiarytDetailsUpdatedData] =
    useState<BeneficiaryDetailsUpdatedValues | any>()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<unknown | null | object>(null)
  const [errorMessage, setErrorMessage] = useState<string>()
  const approval = useContext(ApprovalContext)
  const clientIdValue: any = approval?.value
  const authCtx = useContext(AuthContext)
  const axiosProtected = useAxiosProtected()


  async function retrieveBeneficiaryDetails() {
    if (clientIdValue !== undefined && clientIdValue !== '') {
      try {
        const data = await fetchBeneficiaryDetails(clientIdValue, axiosProtected)
        if (data !== undefined) {
          setBeneficiaryData(data)
          setIsLoading(false)
        }
      } catch (error: unknown) {
        setError(error)
      }
    }
  }

  useEffect(() => {
    if (authCtx?.accessToken) {
      retrieveBeneficiaryDetails()
    }
  }, [clientIdValue, authCtx?.accessToken])

  useEffect(() => {
    async function beneficiaryDetailsSubmission() {
      try {
        if (beneficiaryDetailSubmissionPayload !== undefined) {
          const data: any = await beneficiaryDetailsSubmissionMethod(
            beneficiaryDetailSubmissionPayload,
            axiosProtected
          )
          setErrorMessage(data)
        }
      } catch (error: unknown) {
        console.error(error)
      }
    }
    if (authCtx?.accessToken) {
      beneficiaryDetailsSubmission()
    }
  }, [beneficiaryDetailSubmissionPayload, authCtx?.accessToken])

  return (
    <BeneficiaryContext.Provider
      value={{
        beneficiaryData,
        beneficiaryCollapse,
        beneficiarytDetailsUpdatedData,
        beneficiaryDetailSubmissionPayload,
        setBeneficiaryDetailsSubmissionPayload,
        setBeneficiaryCollapse,
        submissionPopUp,
        setSubmissionPopUp,
        submissionTableData,
        setSubmissionTableData,
        primaryBeneficiaryUpdatedData,
        setPrimaryBeneficiaryUpdatedData,
        contingentUpdatedData,
        setContingentUpdatedData,
        retrieveBeneficiaryDetails
      }}
    >
      {children}
    </BeneficiaryContext.Provider>
  )
}


