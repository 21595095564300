import { AxiosInstance } from 'axios'
import { BeneficiaryDetailsUpdatedValues, BeneficiaryUpdate } from '../../services/beneficiaryContext/BeneficiaryContext'
import { URLs } from '../../services/httpConfiguration'

export async function fetchBeneficiaryDetails(
  accountId: string,
  axiosProtected: AxiosInstance
) {
  const url = URLs.GET_BENEFICIARY_DETAILS
  const payload = {
    accountNumber: accountId,
  }
  const response = await axiosProtected.post(url, payload)
  const data = response.data
  return data
}

export async function beneficiaryDetailsSubmissionMethod(
  payload: BeneficiaryDetailsUpdatedValues | null,
  axiosProtected: AxiosInstance
) {
  try{
  const url = URLs.BENEFICIARY_DETAILS_SUBMISSION
  const response = await axiosProtected.post(url, payload)
  const data = response.status
  return data
  }catch(e){
    return 500
  }
}