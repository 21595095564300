import {
  RootContainer,
  StyledMenuListContainer,
  FlexItem,
  FlexItem3,
  DividerWrapper,
  TagWrap,
  LabelWrap,
  DividerWrapper2,
  DropdownWrap,
} from './styles'
import { SidebarMenuList } from '../../../composites/sidebarMenuList/SidebarMenuList'
import {
  applicationValues,
  approverAndRequesterItemsList,
  approverAndRequesterItemsListWithPledged,
  noUserRoleMenuItemsList,
  requesterAndRestrictionsMenuItemList,
  requesterAndRestrictionsMenuItemListWithPledged,
  restrictionsMenuItemList,
  restrictionsMenuItemListWithPledged,
  sidebarMenuItems,
  sidebarMenuItemsWithPledged,
  sidebarMenuRequesterItems,
  sidebarMenuRequesterItemsWithPledged,
  sidebarRestrictionsMenuItems,
  sidebarRestrictionsMenuItemsWithPledged,
} from '../../../assets/resources'
import {
  WDApplicationHeading,
  WDSidebarClientNickname,
  WDSidebarClientTitle,
} from '../../../components/ui/WDTypography'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { useContext, useState } from 'react'
import Divider from '../../../components/divider/Divider'
import { Tag } from '../../../composites/Tag/Tag'
import Label from '../../../components/label/Label'
import { COLORS } from '../../../theme/Colors'
import { SvgAccountProfileLogo } from '../../../components/svg/SvgAccountProfileLogo'
import { AccountDetailsContext } from '../../../services/accountDetailsContext/AccountDetailsContext'
import { MailingAddressContext } from '../../../services/mailingAddressContext/MailingAddressContext'
import { ApprovalContext } from '../../../services/approvalContext/ApprovalContext'
import { AccountMaintenanceGetContext } from '../../../services/accountMaintenanceGetContext/AccountMaintenanceGetContext'
import { AuthContext } from '../../../services/AuthContext'
import { AccountLoanDetailsContext } from '../../../services/accountLoanDetailsContext/AccountLoanDetailsContext'
import { AiwContext } from '../../../services/aiwContext/AiwContext'

const SidePaneContent = () => {
  const approval = useContext(ApprovalContext)
  const authCtx = useContext(AuthContext)
  const electron = useContext(AiwContext)
  const accountLoanDetailContext = useContext(AccountLoanDetailsContext)
  const noUserRoleMenuItems: string[] = noUserRoleMenuItemsList
  const sideRestrictionsMenuItems: string[] = sidebarRestrictionsMenuItems
  const approverAndRequesterItems: string[] = approverAndRequesterItemsList
  const restrictionsMenuItem: string[] = restrictionsMenuItemList
  const requesterAndRestrictionsMenuItem: string[] = requesterAndRestrictionsMenuItemList
  const sidebarMenu: string[] = sidebarMenuItems
  const sideMenuRequester: string[] = sidebarMenuRequesterItems
  const accountDetails = useContext(AccountDetailsContext)
  const AddressFields = useContext(MailingAddressContext)
  const [userRolesError, setUserRolesError] = useState(undefined)
  const [client, setClient] = useState(
    accountDetails?.accountDetails?.accountType
  )
  // console.log(authCtx?.userRoles, 'auth')
  const contextObject = useContext(AccountMaintenanceGetContext)
  let font: string | undefined = ''
  let bg: string | undefined = ''

  if (client === 'Lead') {
    font = `${COLORS.Text.Warning}`
    bg = `${COLORS.Background.WarningBackground}`
  } else if (client === 'Prospect') {
    font = `${COLORS.Text.Success}`
    bg = `${COLORS.Background.SuccessBackgroundLight}`
  } else {
    font = `${COLORS.Text.Primary}`
    bg = `${COLORS.Background.Primary10}`
  }

  return (
    <RootContainer>
      <FlexItem3>
        {/* <SvgAccountProfileLogo /> */}

        <Label
          fontSize={'26px'}
          padding={'20px 0px 20px 0px'}
          fontWeight={600}
          color={COLORS.Text.PrimaryTextStrong}
          lineHeight={'24px'}
        > Account Profile</Label>


      </FlexItem3>

      <DividerWrapper2>
        <Divider
          bgColor={COLORS.Background.Border}
          horizontal={true}
          thickness="1px"
        />
      </DividerWrapper2>

      {(electron?.inputValue?.input !== undefined ? (
        <StyledMenuListContainer>
          <FlexItem>
            {/* <LabelWrap>
            <WDSidebarClientTitle>{'Peter M. Tassone'}</WDSidebarClientTitle>
          </LabelWrap> */}
            {/* <DropdownWrap id="Target-dropdown">
            <SelectDropdown options={[]} onChange={() => console.log()} defaultValue={{value: '12345678', label: '12345678'}} />
          </DropdownWrap> */}

            <LabelWrap>
              <WDSidebarClientTitle>
                {accountDetails?.accountDetails?.accountNumber}
              </WDSidebarClientTitle>
            </LabelWrap>
            <LabelWrap
              style={{
                textAlign: 'center',
              }}
            >
              <WDSidebarClientNickname>
                {accountDetails?.accountDetails?.fullTitleOfAccount}
              </WDSidebarClientNickname>
            </LabelWrap>
            <LabelWrap>
              <WDSidebarClientNickname>
                {accountDetails?.accountDetails?.accountNumber ? accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith !== 'None' ? accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith : '' : ''}
              </WDSidebarClientNickname>
            </LabelWrap>
            <TagWrap onClick={() => approval?.setApproval(!approval?.approval)}>
              {/* {client === 'Lead' && (
              <Tag ClientType={client} FontColor={font} BgColor={bg} />
            )}
            {client === 'Prospect' && (
              <Tag ClientType={client} FontColor={font} BgColor={bg} />
            )}
            {client === 'Client' && (
              <Tag ClientType={client} FontColor={font} BgColor={bg} />
            )}
             {client === 'Individual' && (
              <Tag ClientType={client} FontColor={font} BgColor={bg} />
            )} */}
              <Tag
                ClientType={accountDetails?.accountDetails?.accountType}
                sidebar={true}
                FontColor={COLORS.Text.Primary}
                BgColor={COLORS.Background.Primary10}
              />
            </TagWrap>
            <DividerWrapper>
              <Divider
                bgColor={COLORS.Background.Border}
                horizontal={true}
                thickness="1px"
              />
            </DividerWrapper>
          </FlexItem>
          {authCtx?.userRoles?.length === 0 && <SidebarMenuList items={noUserRoleMenuItems} />}

          {approval?.admin && authCtx?.userRoles?.includes('AM-RESTRICTIONMANAGEMENT') && (
            <SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? sideRestrictionsMenuItems : sidebarRestrictionsMenuItemsWithPledged} />
          )}

          {approval?.admin && !authCtx?.userRoles?.includes('AM-RESTRICTIONMANAGEMENT') && (
            <SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? approverAndRequesterItems : approverAndRequesterItemsListWithPledged} />
          )}

          {!approval?.admin && approval?.restrictionUser && (
            <SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? restrictionsMenuItem : restrictionsMenuItemListWithPledged} />
          )}

          {approval?.approverAndRestriction && (<SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? sideRestrictionsMenuItems : sidebarRestrictionsMenuItemsWithPledged} />)}

          {!approval?.admin && approval?.approver && (
            <SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? sidebarMenu : sidebarMenuItemsWithPledged} />
          )}
          {approval?.requesterAndRestriction && (<SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? requesterAndRestrictionsMenuItem : requesterAndRestrictionsMenuItemListWithPledged} />)}
          {approval?.requester && <SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? sideMenuRequester : sidebarMenuRequesterItemsWithPledged} />}
        </StyledMenuListContainer>
      ) :
        (electron?.inputValue?.input === undefined && authCtx?.userRoles?.includes('AM-APPROVER')) &&
        <StyledMenuListContainer>
          <FlexItem>
            {/* <LabelWrap>
          <WDSidebarClientTitle>{'Peter M. Tassone'}</WDSidebarClientTitle>
        </LabelWrap> */}
            {/* <DropdownWrap id="Target-dropdown">
          <SelectDropdown options={[]} onChange={() => console.log()} defaultValue={{value: '12345678', label: '12345678'}} />
        </DropdownWrap> */}

            <LabelWrap>
              <WDSidebarClientTitle>
                {accountDetails?.accountDetails?.accountNumber}
              </WDSidebarClientTitle>
            </LabelWrap>
            <LabelWrap
              style={{
                textAlign: 'center',
              }}
            >
              <WDSidebarClientNickname>
                {accountDetails?.accountDetails?.fullTitleOfAccount}
              </WDSidebarClientNickname>
            </LabelWrap>
            <LabelWrap>
              <WDSidebarClientNickname>
                {accountDetails?.accountDetails?.accountNumber ? accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith !== 'None' ? accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith : '' : ''}
              </WDSidebarClientNickname>
            </LabelWrap>
            <TagWrap onClick={() => approval?.setApproval(!approval?.approval)}>
              {/* {client === 'Lead' && (
            <Tag ClientType={client} FontColor={font} BgColor={bg} />
          )}
          {client === 'Prospect' && (
            <Tag ClientType={client} FontColor={font} BgColor={bg} />
          )}
          {client === 'Client' && (
            <Tag ClientType={client} FontColor={font} BgColor={bg} />
          )}
           {client === 'Individual' && (
            <Tag ClientType={client} FontColor={font} BgColor={bg} />
          )} */}
              <Tag
                ClientType={accountDetails?.accountDetails?.accountType}
                sidebar={true}
                FontColor={COLORS.Text.Primary}
                BgColor={COLORS.Background.Primary10}
              />
            </TagWrap>
            <DividerWrapper>
              <Divider
                bgColor={COLORS.Background.Border}
                horizontal={true}
                thickness="1px"
              />
            </DividerWrapper>
          </FlexItem>
          {authCtx?.userRoles?.length === 0 && <SidebarMenuList items={noUserRoleMenuItems} />}

          {approval?.admin && authCtx?.userRoles?.includes('AM-RESTRICTIONMANAGEMENT') && (
            <SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? sideRestrictionsMenuItems : sidebarRestrictionsMenuItemsWithPledged} />
          )}

          {approval?.admin && !authCtx?.userRoles?.includes('AM-RESTRICTIONMANAGEMENT') && (
            <SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? approverAndRequesterItems : approverAndRequesterItemsListWithPledged} />
          )}

          {!approval?.admin && approval?.restrictionUser && (
            <SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? restrictionsMenuItem : restrictionsMenuItemListWithPledged} />
          )}

          {approval?.approverAndRestriction && (<SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? sideRestrictionsMenuItems : sidebarRestrictionsMenuItemsWithPledged} />)}

          {!approval?.admin && approval?.approver && (
            <SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? sidebarMenu : sidebarMenuItemsWithPledged} />
          )}
          {approval?.requesterAndRestriction && (<SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? requesterAndRestrictionsMenuItem : requesterAndRestrictionsMenuItemListWithPledged} />)}
          {approval?.requester && <SidebarMenuList items={accountLoanDetailContext?.pledgedAccountData?.accountPledgedWith === 'None' && !authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') ? sideMenuRequester : sidebarMenuRequesterItemsWithPledged} />}
        </StyledMenuListContainer>
      )
      }
    </RootContainer>
  )
}

export default SidePaneContent
