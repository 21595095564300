import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import Button from '../../components/button/Button'
import Divider from '../../components/divider/Divider'
import Label from '../../components/label/Label'
import Loader from '../../components/loader/Loader'
import { SvgAddressChange } from '../../components/svg/SvgAddressChange'
import { SvgChevronDown } from '../../components/svg/SvgChevronDown'
import { SvgChevronUp } from '../../components/svg/SvgChevronUp'
import { SvgRecycle } from '../../components/svg/SvgRecycle'
import {
  WDStyledAddAddressButton,
  WDStyledCancelButton,
  WDStyledEditButton,
  WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import {
  WDInputLabel,
  WDReadModeTitle,
  WDReadModeValue,
  WDStyledCancelButtonLabel,
  WDStyledEditButtonLabel,
  WDStyledSectionTitle,
  WDStyledSubSectionTitle,
} from '../../components/ui/WDTypography'
import { MailingAddressContext } from '../../services/mailingAddressContext/MailingAddressContext'
import {
  AddressList,
  MailingAddressInterface,
  States,
} from '../../services/mailingAddressContext/MailingAddressInterface'
import { COLORS } from '../../theme/Colors'
import { DropdownField } from '../dropdownField/DropdownField'
import { InputField } from '../inputField/InputField'
import {
  ButtonRow,
  ButtonRow1,
  DividerWrapper,
  DividerWrapper2,
  EditWrapper,
  FlexItem,
  FlexItem1,
  FlexItem2,
  HeaderRow,
  IconWrapper,
  LabelWrap,
  LegalTab,
  LoaderRow,
  MasterWrapper,
  PrimaryButtonWrapper,
  Row,
  SecondaryButtonWrapper,
  SecondaryButtonWrapper1,
  StyledActiveButtonLabel,
  StyledHeadWrapper,
  SubSectionWrapper,
  TertiaryButtonWrapper,
  TitleIconWrap,
  TopRow,
} from './styles'
import { ErrorModal } from '../errorModal/ErrorModal'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'
import { ApprovalContext } from '../../services/approvalContext/ApprovalContext'
import { AuthContext } from '../../services/AuthContext'
import { InlineErrorWrapper } from '../mailingAddress/styles'
import { SvgExclamation } from '../../components/svg/SvgExclamation'
import { checkIsP3Account, checkZipCode } from '../../shared/helper'
import { messages } from '../../shared/constants'
import { AuditTrailContext } from '../../services/auditTrailContext/AuditTrailContext'

export const AddressUI = () => {
  const authCtx = useContext(AuthContext)
  const AddressFields = useContext(MailingAddressContext)
  const accountDetails = useContext(AccountDetailsContext)
  const auditTrailValues = useContext(AuditTrailContext)
  const approval = useContext(ApprovalContext)
  const err = AddressFields?.errorMsg
  const delError = AddressFields?.deleteErrorMsg
  const [valid, setValid] = useState(true)
  const [validAlternate, setValidAlternate] = useState(true)
  const [validStartDate, setValidStartDate] = useState(true)
  const [validEndDate, setValidEndDate] = useState(true)
  const [validZipCode, setValidZipCode] = useState(true)
  const [validAlternateZipCode, setValidAlternateZipCode] = useState(true)
  const [clickedCancel, setClickedCancel] = useState(false)
  const [checkDate, setCheckDate] = useState<boolean>(true)
  const [checkStartDate, setCheckStartDate] = useState<boolean>(true)
  const [startDateError, setStartDateError] = useState<boolean>(false)
  const [activeSection, setActiveSection] = useState(false)
  const [errorStreetAddress1Alternate, setErrorStreetAddress1Alternate] =
    useState<boolean>(false)
  const [errorCityNameAlternate, setErrorCityNameAlternate] =
    useState<boolean>(false)
  const [errorStateNameAlternate, setErrorStateNameAlternate] =
    useState<boolean>(false)
  const [errorZipCodeAlternate, setErrorZipCodeAlternate] =
    useState<boolean>(false)
  const [errorStreetAddress1, setErrorStreetAddress1] = useState<boolean>(false)
  const [errorCityName, setErrorCityName] = useState<boolean>(false)
  const [errorStateName, setErrorStateName] = useState<boolean>(false)
  const [errorZipCode, setErrorZipCode] = useState<boolean>(false)

  const addressList = AddressFields?.mailingAddress

  const [mail, setMail] = useState<MailingAddressInterface[]>([])
  const [alternate, setAlternate] = useState<MailingAddressInterface[]>([
    {
      addressNickName: '',
      streetAddress1: '',
      streetAddress2: '',
      cityName: '',
      stateName: '',
      country: '',
      zipCode: '',
      startDate: '',
      endDate: '',
    },
  ])

  useEffect(() => {
    if (addressList !== undefined) {
      const tempOne = addressList?.filter(
        (mailingAddressType) =>
          mailingAddressType.addressType === 'MailingAddress'
      )
      setMail(tempOne)
      const tempTwo = addressList?.filter(
        (mailingAddressType) => mailingAddressType.addressType === 'Alternate'
      )
      setAlternate(tempTwo)
    }
  }, [addressList])

  useEffect(() => {
    mail
    alternate
  }, [])

  const [addUpdateAddresses, setAddUpdateAddresses] = useState<AddressList[]>(
    []
  )
  const [addUpdateAlternateAddresses, setAddUpdateAlternateAddresses] =
    useState<AddressList[]>([])
  const [additional, setAdditional] = useState<MailingAddressInterface[]>([])

  const [addUpdateAddressesOriginal, setAddUpdateAddressesOriginal] = useState<
    AddressList[]
  >([])
  const [
    addUpdateAlternateAddressesOriginal,
    setAddUpdateAlternateAddressesOriginal,
  ] = useState<AddressList[]>([])

  useEffect(() => {
    setAddUpdateAddressesOriginal([
      {
        accountId: accountDetails?.accountDetails?.accountId,
        accountNumber: approval?.value,
        addressTypeId: mail[0]?.addressTypeId ? mail[0]?.addressTypeId : 2,
        addressType: 'MailingAddress',
        addressId: mail[0]?.addressId ? mail[0]?.addressId : 0,
        streetAddress1: mail[0]?.streetAddress1 ? mail[0]?.streetAddress1 : '',
        streetAddress2: mail[0]?.streetAddress2 ? mail[0]?.streetAddress2 : '',
        streetAddress3: '',
        cityId: mail[0]?.city ? mail[0]?.city : 0,
        cityName: mail[0]?.cityName ? mail[0]?.cityName : '',
        stateProvinceId:
          mail[0]?.countryName === 'United States of America'
            ? mail[0]?.state
            : 0,
        stateProvinceName:
          mail[0]?.countryName === 'United States of America'
            ? mail[0]?.stateName
            : '',
        stateCode:
          mail[0]?.countryName === 'United States of America'
            ? mail[0]?.stateCode
            : '',
        countrycode: mail[0]?.country ? mail[0]?.country : '',
        countryName: mail[0]?.countryName ? mail[0]?.countryName : '',
        zipCode: mail[0]?.zipCode ? mail[0]?.zipCode : '',
        statusId: 0,
        updateBy: authCtx?.account?.name,
        createdBy: authCtx?.account?.name,
        createdOn: '2023-06-02 11:37:36.524582+00',
        updateOn: '2023-06-02 11:37:36.524582+00',
      },
    ])

    setAddUpdateAlternateAddressesOriginal([
      {
        accountId: accountDetails?.accountDetails?.accountId,
        accountNumber: approval?.value,
        addressTypeId: alternate[0]?.addressTypeId
          ? alternate[0]?.addressTypeId
          : 4,
        addressType: 'Alternate',
        addressId: alternate[0]?.addressId ? alternate[0]?.addressId : 0,
        streetAddress1: alternate[0]?.streetAddress1
          ? alternate[0]?.streetAddress1
          : '',
        streetAddress2: alternate[0]?.streetAddress2
          ? alternate[0]?.streetAddress2
          : '',
        streetAddress3: '',
        cityId: alternate[0]?.city ? alternate[0]?.city : 0,
        cityName: alternate[0]?.cityName ? alternate[0]?.cityName : '',
        stateProvinceId:
          alternate[0]?.countryName === 'United States of America'
            ? alternate[0]?.state
            : 0,
        stateProvinceName:
          alternate[0]?.countryName === 'United States of America'
            ? alternate[0]?.stateName
            : '',
        stateCode:
          alternate[0]?.countryName === 'United States of America'
            ? alternate[0]?.stateCode
            : '',
        countrycode: alternate[0]?.country ? alternate[0]?.country : '',
        countryName: alternate[0]?.countryName ? alternate[0]?.countryName : '',
        zipCode: alternate[0]?.zipCode ? alternate[0]?.zipCode : '',
        statusId: 0,
        updateBy: authCtx?.account?.name,
        createdBy: authCtx?.account?.name,
        addressNickName: alternate[0]?.addressNickName
          ? alternate[0]?.addressNickName
          : '',
        startDate: alternate[0]?.startDate
          ? new Date(alternate[0]?.startDate).toISOString().split('T')[0]
          : '',
        endDate: alternate[0]?.endDate
          ? new Date(alternate[0]?.endDate).toISOString().split('T')[0]
          : '',
        createdOn: '2023-06-02 11:37:36.524582+00',
        updateOn: '2023-06-02 11:37:36.524582+00',
      },
    ])
  }, [
    accountDetails?.accountDetails?.accountId,
    alternate,
    approval?.value,
    mail,
    authCtx?.account?.name,
    clickedCancel,
  ])

  useEffect(() => {
    setAddUpdateAddresses([
      {
        accountId: accountDetails?.accountDetails?.accountId,
        accountNumber: approval?.value,
        addressTypeId: mail[0]?.addressTypeId ? mail[0]?.addressTypeId : 2,
        addressType: 'MailingAddress',
        addressId: mail[0]?.addressId ? mail[0]?.addressId : 0,
        streetAddress1: mail[0]?.streetAddress1 ? mail[0]?.streetAddress1 : '',
        streetAddress2: mail[0]?.streetAddress2 ? mail[0]?.streetAddress2 : '',
        streetAddress3: '',
        cityId: mail[0]?.city ? mail[0]?.city : 0,
        cityName: mail[0]?.cityName ? mail[0]?.cityName : '',
        stateProvinceId:
          mail[0]?.countryName === 'United States of America'
            ? mail[0]?.state
            : 0,
        stateProvinceName:
          mail[0]?.countryName === 'United States of America'
            ? mail[0]?.stateName
            : '',
        stateCode:
          mail[0]?.countryName === 'United States of America'
            ? mail[0]?.stateCode
            : '',
        countrycode: mail[0]?.country ? mail[0]?.country : '',
        countryName: mail[0]?.countryName ? mail[0]?.countryName : '',
        zipCode: mail[0]?.zipCode ? mail[0]?.zipCode : '',
        statusId: 0,
        updateBy: authCtx?.account?.name,
        createdBy: authCtx?.account?.name,
        createdOn: '2023-06-02 11:37:36.524582+00',
        updateOn: '2023-06-02 11:37:36.524582+00',
      },
    ])

    setAddUpdateAlternateAddresses([
      {
        accountId: accountDetails?.accountDetails?.accountId,
        accountNumber: approval?.value,
        addressTypeId: alternate[0]?.addressTypeId
          ? alternate[0]?.addressTypeId
          : 4,
        addressType: 'Alternate',
        addressId: alternate[0]?.addressId ? alternate[0]?.addressId : 0,
        streetAddress1: alternate[0]?.streetAddress1
          ? alternate[0]?.streetAddress1
          : '',
        streetAddress2: alternate[0]?.streetAddress2
          ? alternate[0]?.streetAddress2
          : '',
        streetAddress3: '',
        cityId: alternate[0]?.city ? alternate[0]?.city : 0,
        cityName: alternate[0]?.cityName ? alternate[0]?.cityName : '',
        stateProvinceId:
          alternate[0]?.countryName === 'United States of America'
            ? alternate[0]?.state
            : 0,
        stateProvinceName:
          alternate[0]?.countryName === 'United States of America'
            ? alternate[0]?.stateName
            : '',
        stateCode:
          alternate[0]?.countryName === 'United States of America'
            ? alternate[0]?.stateCode
            : '',
        countrycode: alternate[0]?.country ? alternate[0]?.country : '',
        countryName: alternate[0]?.countryName ? alternate[0]?.countryName : '',
        zipCode: alternate[0]?.zipCode ? alternate[0]?.zipCode : '',
        statusId: 0,
        updateBy: authCtx?.account?.name,
        createdBy: authCtx?.account?.name,
        addressNickName: alternate[0]?.addressNickName
          ? alternate[0]?.addressNickName
          : '',
        startDate: alternate[0]?.startDate
          ? new Date(alternate[0]?.startDate).toISOString().split('T')[0]
          : '',
        endDate: alternate[0]?.endDate
          ? new Date(alternate[0]?.endDate).toISOString().split('T')[0]
          : '',
        createdOn: '2023-06-02 11:37:36.524582+00',
        updateOn: '2023-06-02 11:37:36.524582+00',
      },
    ])
  }, [
    accountDetails?.accountDetails?.accountId,
    approval?.value,
    mail,
    authCtx?.account?.name,
    clickedCancel,
  ])

  const [alternateChanged, setAlternateChanged] = useState(false)

  useEffect(() => {
    const additionalAddresses = alternate?.map((item) => item)?.slice(1)
    setAdditional(additionalAddresses)
    setAddUpdateAlternateAddresses([
      {
        accountId: accountDetails?.accountDetails?.accountId,
        accountNumber: approval?.value,
        addressTypeId: alternate[0]?.addressTypeId
          ? alternate[0]?.addressTypeId
          : 4,
        addressType: 'Alternate',
        addressId: alternate[0]?.addressId ? alternate[0]?.addressId : 0,
        streetAddress1: alternate[0]?.streetAddress1
          ? alternate[0]?.streetAddress1
          : '',
        streetAddress2: alternate[0]?.streetAddress2
          ? alternate[0]?.streetAddress2
          : '',
        streetAddress3: '',
        cityId: alternate[0]?.city ? alternate[0]?.city : 0,
        cityName: alternate[0]?.cityName ? alternate[0]?.cityName : '',
        stateProvinceId: alternate[0]?.state ? alternate[0]?.state : 0,
        stateProvinceName: alternate[0]?.stateName
          ? alternate[0]?.stateName
          : '',
        stateCode: alternate[0]?.stateCode ? alternate[0]?.stateCode : '',
        countrycode: alternate[0]?.country || alternateChanged ? 'US' : '',
        countryName:
          alternate[0]?.countryName || alternateChanged
            ? 'United States of America'
            : '',
        zipCode: alternate[0]?.zipCode ? alternate[0]?.zipCode : '',
        statusId: 0,
        updateBy: authCtx?.account?.name,
        createdBy: authCtx?.account?.name,
        addressNickName: alternate[0]?.addressNickName
          ? alternate[0]?.addressNickName
          : '',
        startDate: alternate[0]?.startDate
          ? new Date(alternate[0]?.startDate).toISOString().split('T')[0]
          : '',
        endDate: alternate[0]?.endDate
          ? new Date(alternate[0]?.endDate).toISOString().split('T')[0]
          : '',
        createdOn: '2023-06-02 11:37:36.524582+00',
        updateOn: '2023-06-02 11:37:36.524582+00',
      },
    ])
  }, [alternate, alternateChanged])

  interface AddressObject {
    addressId?: string | number | undefined | null
    addressType: string | number | undefined | null
    addressTypeId?: string | number | undefined | null
    streetAddress1: string | number | undefined | null
    streetAddress2: string | number | undefined | null
    city: string | number | undefined | null
    state: string | number | undefined | null
    stateProvinceId?: string | number | undefined | null
    country: string | number | undefined | null
    countryAlpha2Code?: string | number | undefined | null
    zipCode: string | number | undefined | null
  }

  const [additionalAddressArray, setAdditionalAddressArray] = useState<
    AddressObject[] | any
  >([])

  const statesOptions = AddressFields?.states.map(
    ({ stateProvinceId, stateProvinceName, stateProvinceShortCode }) => {
      return {
        value: stateProvinceId,
        label: stateProvinceName,
        stateShortCode: stateProvinceShortCode,
      }
    }
  )

  const countriesOptions = AddressFields?.countries.map(
    ({ countryName, countryAlpha2Code }) => {
      return {
        value: countryAlpha2Code,
        label: countryName,
      }
    }
  )

  const formPr = useRef<HTMLFormElement>(null)

  const PermanentAddressDetails = {
    'Street Address 1': 'Main ',
    'Street Address 2': 'Elite',
    City: 'New York',
    State: 'GA',
    Country: 'India',
    'Zip Code': '27491',
    'Start Date': '12/10/2019',
    'End Date': '11/09/2018',
  }

  const [mailingAddress, setMailingAddress] = useState({
    streetAddress1: `100 WILSHIRE Blvd`,
    streetAddress2: 'First street',
    city: 'Los Angeles',
    state: 'California',
    country: 'United States of America',
    zipCode: '16309',
  })

  const [alternateAddress, setAlternateAddress] = useState({
    addressNickName: '',
    streetAddress1: '100 Wilshire Masnion',
    streetAddress2: 'Second Avenue',
    city: 'Los Angeles',
    state: 'California',
    country: 'United States of America',
    zipCode: '16322',
    startDate: '2022/05/10',
    endDate: '2022/05/10',
  })

  useEffect(() => {
    setMailingAddress({
      streetAddress1: mail[0]?.streetAddress1 ? mail[0]?.streetAddress1 : '',
      streetAddress2: mail[0]?.streetAddress2 ? mail[0]?.streetAddress2 : '',
      city: mail[0]?.cityName ? mail[0]?.cityName : '',
      state: mail[0]?.stateName ? mail[0]?.stateName : '',
      country: mail[0]?.countryName ? mail[0]?.countryName : '',
      zipCode: mail[0]?.zipCode ? mail[0]?.zipCode : '',
    })
  }, [mail])
  const [showSection, setShowSection] = useState(false)
  const [clicked, setClicked] = useState(false)
  useEffect(() => {
    if (alternate?.length > 0) {
      setShowSection(false)
    }
  }, [alternate?.length])

  useEffect(() => {
    if (clicked) {
      setShowSection(true)
    }
  }, [clicked])

  useEffect(() => {
    setAlternateAddress({
      addressNickName: alternate[0]?.addressNickName
        ? alternate[0]?.addressNickName
        : '',
      streetAddress1: alternate[0]?.streetAddress1
        ? alternate[0]?.streetAddress1
        : '',
      streetAddress2: alternate[0]?.streetAddress2
        ? alternate[0]?.streetAddress2
        : '',
      city: alternate[0]?.cityName ? alternate[0]?.cityName : '',
      state: alternate[0]?.stateName ? alternate[0]?.stateName : '',
      country: alternate[0]?.countryName ? alternate[0]?.countryName : '',
      zipCode: alternate[0]?.zipCode ? alternate[0]?.zipCode : '',
      startDate: alternate[0]?.startDate
        ? new Date(alternate[0]?.startDate).toISOString().split('T')[0]
        : '',
      endDate: alternate[0]?.endDate
        ? new Date(alternate[0]?.endDate).toISOString().split('T')[0]
        : '',
    })
  }, [alternate])

  const address_keysPr = Object.keys(PermanentAddressDetails)
  const address_valuesPr = Object.values(mailingAddress)
  const alternateAddressValues = Object.values(alternateAddress)
  const address_lengthPr = ['1', '2', '3', '4']
  const address_length = ['1', '2']

  const [indices, setIndices] = useState<number[]>([])
  const [mailingChanged, setMailingChanged] = useState(false)

  const addressListLength = alternate?.length - 1

  useEffect(() => {
    const getStateName = (stateProvinceShortCode: string) => {
      return AddressFields?.states?.find((states: States) => {
        return states?.stateProvinceShortCode === stateProvinceShortCode
      })?.stateProvinceName
    }
    const getStateProvinceId = (stateProvinceShortCode: string) => {
      return AddressFields?.states?.find((states: States) => {
        return states?.stateProvinceShortCode === stateProvinceShortCode
      })?.stateProvinceId
    }
    // if (showSection) {
    if (
      addUpdateAlternateAddresses[0]?.countryName === 'United States of America'
    ) {
      const autoDataAlternate = [...addUpdateAlternateAddresses]
      autoDataAlternate[0] = {
        ...autoDataAlternate[0],
        cityName: AddressFields?.addressValidateAlternate?.city
          ? AddressFields?.addressValidateAlternate?.city
          : '',
        stateCode: AddressFields?.addressValidateAlternate?.stateProvince
          ? AddressFields?.addressValidateAlternate?.stateProvince
          : '',
        stateProvinceName: getStateName(
          AddressFields?.addressValidateAlternate?.stateProvince as string
        ),
        stateProvinceId: getStateProvinceId(
          AddressFields?.addressValidateAlternate?.stateProvince as string
        ),
        countrycode: 'US',
        countryName: 'United States of America',
      }
      setAddUpdateAlternateAddresses(autoDataAlternate)
    }
    if (addUpdateAddresses[0]?.countryName === 'United States of America') {
      const autoDataMailing = [...addUpdateAddresses]
      autoDataMailing[0] = {
        ...autoDataMailing[0],
        cityName: AddressFields?.addressValidateData?.city
          ? AddressFields?.addressValidateData?.city
          : '',
        stateCode: AddressFields?.addressValidateData?.stateProvince
          ? AddressFields?.addressValidateData?.stateProvince
          : '',
        stateProvinceName: getStateName(
          AddressFields?.addressValidateData?.stateProvince as string
        ),
        stateProvinceId: getStateProvinceId(
          AddressFields?.addressValidateData?.stateProvince as string
        ),
        countrycode: 'US',
        countryName: 'United States of America',
      }
      setAddUpdateAddresses(autoDataMailing)
    }
  }, [
    AddressFields?.addressValidateData,
    AddressFields?.addressValidateAlternate,
  ])

  useEffect(() => {
    if (
      addUpdateAddresses[0]?.countryName === 'United States of America' ||
      addUpdateAlternateAddresses[0]?.countryName === 'United States of America'
    ) {
      const zipCodeMailing: any = addUpdateAddresses[0]?.zipCode
      if (checkZipCode(zipCodeMailing))
        AddressFields?.setZipCode(zipCodeMailing)
      const zipCodeAlternate: any = addUpdateAlternateAddresses[0]?.zipCode
      if (checkZipCode(zipCodeAlternate))
        AddressFields?.setZipCodeAlternate(zipCodeAlternate)
    }
  }, [AddressFields, addUpdateAddresses, addUpdateAlternateAddresses])

  const handleAddressNickName = (e: string, index: number, type: string) => {
    if (type === 'Alternate') {
      setAlternateChanged(true)
      const tempData = [...addUpdateAlternateAddresses]
      tempData[0] = { ...tempData[0], addressNickName: e }
      setAddUpdateAlternateAddresses([...tempData])
      // setAlternate([{ ...alternate[0], addressNickName: e }])
    }
    if (type === 'Additional') {
      if (!(index + 1 > addressListLength)) {
        setIndices([...indices, index])
      }
      const tempData = [...additional]
      tempData[index] = { ...tempData[index], addressNickName: e }
      setAdditional(tempData)
    }
  }

  const handleStartDate = (e: string, index: number, type: string) => {
    setValidStartDate(true)
    setCheckStartDate(true)
    setStartDateError(false)
    const selectedDate = new Date(e)
    const minDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate() + 2
    )
      .toISOString()
      .split('T')[0]
    document.getElementById('endDate')?.setAttribute('min', minDate)
    if (type === 'Alternate') {
      setAlternateChanged(true)
      const tempData = [...addUpdateAlternateAddresses]
      tempData[0] = { ...tempData[0], startDate: e }
      setAddUpdateAlternateAddresses(tempData)
      // setAlternate([{ ...alternate[0], startDate: e }])
    }
    if (type === 'Additional') {
      if (!(index + 1 > addressListLength)) {
        setIndices([...indices, index])
      }
      const tempData = [...additional]
      tempData[index] = { ...tempData[index], startDate: e }
      setAdditional(tempData)
    }
  }

  const handleEndDate = (e: string, index: number, type: string) => {
    if (type === 'Alternate') {
      setAlternateChanged(true)
      setCheckStartDate(true)
      setValidEndDate(true)
      const tempData = [...addUpdateAlternateAddresses]
      tempData[0] = { ...tempData[0], endDate: e }
      setAddUpdateAlternateAddresses(tempData)
      // setAlternate([{ ...alternate[0], endDate: e }])
      setCheckDate(true)
    }
    if (type === 'Additional') {
      if (!(index + 1 > addressListLength)) {
        setIndices([...indices, index])
      }
      const tempData = [...additional]
      tempData[index] = { ...tempData[index], endDate: e }
      setAdditional(tempData)
    }
  }

  const handleFormStreetAddressOne = (
    e: string,
    index: number,
    type: string
  ) => {
    if (type === 'Mailing') {
      // setMailingChanged(true)
      const tempData = [...addUpdateAddresses]
      tempData[0] = { ...tempData[0], streetAddress1: e }
      setAddUpdateAddresses(tempData)
      setErrorStreetAddress1(e === '')
    }
    if (type === 'Alternate') {
      setAlternateChanged(true)
      const tempData = [...addUpdateAlternateAddresses]
      tempData[0] = { ...tempData[0], streetAddress1: e }
      // setAddUpdateAlternateAddresses(tempData)
      setAddUpdateAlternateAddresses([
        { ...addUpdateAlternateAddresses[0], streetAddress1: e },
      ])
      setErrorStreetAddress1Alternate(e === '')
    }
    if (type === 'Additional') {
      if (!(index + 1 > addressListLength)) {
        setIndices([...indices, index])
      }
      const tempData = [...additional]
      tempData[index] = { ...tempData[index], streetAddress1: e }
      setAdditional(tempData)
    }
  }

  const handleFormStreetAddressTwo = (
    e: string,
    index: number,
    type: string
  ) => {
    if (type === 'Mailing') {
      // setMailingChanged(true)
      const tempData = [...addUpdateAddresses]
      tempData[0] = { ...tempData[0], streetAddress2: e }
      setAddUpdateAddresses(tempData)
    }
    if (type === 'Alternate') {
      setAlternateChanged(true)
      const tempData = [...addUpdateAlternateAddresses]
      tempData[0] = { ...tempData[0], streetAddress2: e }
      setAddUpdateAlternateAddresses(tempData)
      // setAlternate([{ ...alternate[0], streetAddress2: e }])
    }
    if (type === 'Additional') {
      if (!(index + 1 > addressListLength)) {
        setIndices([...indices, index])
      }
      const tempData = [...additional]
      tempData[index] = { ...tempData[index], streetAddress2: e }
      setAdditional(tempData)
    }
  }

  const handleFormCity = (e: string, index: number, type: string) => {
    if (type === 'Mailing') {
      // setMailingChanged(true)
      const tempData = [...addUpdateAddresses]
      tempData[0] = { ...tempData[0], cityName: e }
      setAddUpdateAddresses(tempData)
      const zipCodeMailing: any = addUpdateAddresses[0]?.zipCode
      if (checkZipCode(zipCodeMailing))
        AddressFields?.setZipCode(zipCodeMailing)
      setValid(true)
      setErrorCityName(e === '')
    }
    if (type === 'Alternate') {
      setAlternateChanged(true)
      const tempData = [...addUpdateAlternateAddresses]
      tempData[0] = { ...tempData[0], cityName: e }
      setAddUpdateAlternateAddresses(tempData)
      const zipCodeAlternate: any = addUpdateAlternateAddresses[0]?.zipCode
      if (checkZipCode(zipCodeAlternate))
        AddressFields?.setZipCodeAlternate(zipCodeAlternate)
      // setAlternate([{ ...alternate[0], cityName: e }])
      setValidAlternate(true)
      setErrorCityNameAlternate(e === '')
    }
    if (type === 'Additional') {
      if (!(index + 1 > addressListLength)) {
        setIndices([...indices, index])
      }
      const tempData = [...additional]
      tempData[index] = { ...tempData[index], cityName: e }
      setAdditional(tempData)
      const zipCodeAdditional: any = additional[index]?.zipCode
      AddressFields?.setZipCodeAlternate(zipCodeAdditional)
      setValidAlternate(true)
    }
  }
  const [isP3Account, setIsP3Account] = useState(false)

  console.log('IsP3Account', isP3Account)
  useMemo(() => {
    if (accountDetails?.accountDetails?.system != null) {
      setIsP3Account(
        checkIsP3Account(accountDetails?.accountDetails?.system as string)
      )
    }
  }, [accountDetails?.accountDetails?.system])

  const handleFormState = (
    e: string | number | undefined,
    index: number,
    type: string
  ) => {
    if (type === 'Mailing' && e !== undefined) {
      setErrorStateName(e === '')
      if (addUpdateAddresses[0]?.countryName !== 'United States of America' && isP3Account) {
        const tempData = [...addUpdateAddresses]
        tempData[0] = { ...tempData[0], stateProvinceId: 0 }
        tempData[0] = { ...tempData[0], stateCode: e as string }
        tempData[0] = { ...tempData[0], stateProvinceName: e as string }
        setAddUpdateAddresses(tempData)
      } else {
        const stateProvinceId = Object.values(e)[0]
        const stateName = Object.values(e)[1]
        const stateCode = Object.values(e)[2]
        const tempData = [...addUpdateAddresses]
        tempData[0] = { ...tempData[0], stateProvinceId: stateProvinceId }
        tempData[0] = { ...tempData[0], stateCode: stateCode }
        tempData[0] = { ...tempData[0], stateProvinceName: stateName }
        setAddUpdateAddresses(tempData)
        const zipCodeMailing: any = addUpdateAddresses[0]?.zipCode
        if (checkZipCode(zipCodeMailing))
          AddressFields?.setZipCode(zipCodeMailing)
        setValid(true)
      }
    }
    if (type === 'Alternate' && e !== undefined) {
      const stateProvinceId = Object.values(e)[0]
      const stateName = Object.values(e)[1]
      setAlternateChanged(true)
      const stateShortCut = Object.values(e)[2]
      const tempData = [...addUpdateAlternateAddresses]
      tempData[0] = { ...tempData[0], stateProvinceId: stateProvinceId }
      tempData[0] = { ...tempData[0], stateCode: stateShortCut }
      tempData[0] = { ...tempData[0], stateProvinceName: stateName }
      setAddUpdateAlternateAddresses(tempData)
      const zipCodeAlternate: any = addUpdateAlternateAddresses[0]?.zipCode
      if (checkZipCode(zipCodeAlternate))
        AddressFields?.setZipCodeAlternate(zipCodeAlternate)
      setValidAlternate(true)
      if (addUpdateAlternateAddresses[0]?.countrycode !== 'US')
        setErrorStateNameAlternate(false)
      else setErrorStateNameAlternate(e === '')
    }
    if (type === 'Additional' && e !== undefined) {
      const stateProvinceId = Object.values(e)[0]
      if (!(index + 1 > addressListLength)) {
        setIndices([...indices, index])
      }
      const stateShortCut = Object.values(e)[2]
      const tempData = [...additional]
      tempData[index] = { ...tempData[index], state: stateProvinceId }
      tempData[0] = { ...tempData[0], stateCode: stateShortCut }
      setAdditional(tempData)
      const zipCodeAdditional: any = additional[index]?.zipCode

      AddressFields?.setZipCodeAlternate(zipCodeAdditional)
      setValidAlternate(true)
    }
  }

  const handleFormCountry = (
    e: string | number | undefined,
    index: number,
    type: string
  ) => {
    setErrorStateName(false)
    setValid(true)
    if (type === 'Mailing' && e !== undefined) {
      approval?.setCountryValid(true)
      const countryId = Object.values(e)[0]
      const countryName = Object.values(e)[1]
      const tempData = [...addUpdateAddresses]
      tempData[0] = {
        ...tempData[0],
        countrycode: countryId,
        countryName: countryName,
        cityName: '',
        zipCode: '',
        stateProvinceName: '',
        stateProvinceId: 0,
        stateCode: '',
      }
      setAddUpdateAddresses(tempData)
      setValidZipCode(true)
    }
    if (type === 'Alternate' && e !== undefined) {
      const countryId = Object.values(e)[0]
      const countryName = Object.values(e)[1]
      setAlternateChanged(true)
      const tempData = [...addUpdateAlternateAddresses]
      tempData[0] = {
        ...tempData[0],
        countrycode: countryId,
        countryName: countryName,
        cityName: '',
        zipCode: '',
        stateProvinceName: '',
        stateProvinceId: 0,
        stateCode: '',
      }
      setAddUpdateAlternateAddresses(tempData)
      setValidAlternateZipCode(true)
    }
    if (type === 'Additional' && e !== undefined) {
      const countryId = Object.values(e)[0]
      if (!(index + 1 > addressListLength)) {
        setIndices([...indices, index])
      }
      const tempData = [...additional]
      tempData[index] = { ...tempData[index], country: countryId }
      setAdditional(tempData)
    }
  }

  const handleFormZipCode = (e: string, index: number, type: string) => {
    const numbers = /^[0-9]+$/
    if (type === 'Mailing') {
      // setMailingChanged(true)
      const tempData = [...addUpdateAddresses]
      tempData[0] = { ...tempData[0], zipCode: e }
      setAddUpdateAddresses(tempData)
      if (addUpdateAddresses[0]?.countryName === 'United States of America') {
        if (e.match(numbers)) {
          if (e.length > 5 || e.length < 5) {
            setValidZipCode(false)
          } else {
            setValidZipCode(true)
            const zipCodeMailing = e
            if (checkZipCode(zipCodeMailing))
              AddressFields?.setZipCode(zipCodeMailing)
            setValid(true)
          }
        } else {
          setValidZipCode(false)
        }
      } else {
        setValidZipCode(true)
        setValid(true)
      }
      setErrorZipCode(e === '')
      setErrorCityName(false)
    }
    if (type === 'Alternate') {
      setAlternateChanged(true)
      const tempData = [...addUpdateAlternateAddresses]
      tempData[0] = { ...tempData[0], zipCode: e }
      setAddUpdateAlternateAddresses(tempData)
      if (
        addUpdateAlternateAddresses[0]?.countryName ===
        'United States of America'
      ) {
        if (e.match(numbers) && alternateChanged) {
          // setAlternate([{ ...alternate[0], zipCode: e }])
          if (e.length > 5 || e.length < 5) {
            setValidAlternateZipCode(false)
          } else {
            setValidAlternateZipCode(true)
            const zipCodeAlternate = e
            if (checkZipCode(zipCodeAlternate))
              AddressFields?.setZipCodeAlternate(zipCodeAlternate)
            setValidAlternate(true)
          }
        } else {
          setValidAlternateZipCode(false)
        }
      } else {
        setValidAlternateZipCode(true)
        const zipCodeAlternate = e
        AddressFields?.setZipCodeAlternate(zipCodeAlternate)
        setValidAlternate(true)
      }
      setErrorZipCodeAlternate(e === '')
      setErrorCityNameAlternate(false)
    }
    if (type === 'Additional') {
      if (!(index + 1 > addressListLength)) {
        setIndices([...indices, index])
      }
      const tempData = [...additional]
      tempData[index] = { ...tempData[index], zipCode: e }
      setAdditional(tempData)
      const zipCodeAdditional = e
      AddressFields?.setZipCodeAlternate(zipCodeAdditional)
      setValidAlternate(true)
    }
  }

  const distinct = (arr: number[]) => {
    const unique = Array.from(new Set(arr))
    return unique
  }

  const currentDate = new Date().toISOString().split('T')[0]
  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0]
    if (alternateAddressValues[7] !== undefined) {
      if (alternateAddressValues[7] === '') {
        document.getElementById('startDate')?.setAttribute('min', currentDate)
        const selectedDate = new Date(currentDate)
        const minDate = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate() + 2
        )
          .toISOString()
          .split('T')[0]
        document.getElementById('endDate')?.setAttribute('min', minDate)
      } else {
        if (alternateAddressValues[7] > currentDate)
          document.getElementById('startDate')?.setAttribute('min', currentDate)
        else
          document
            .getElementById('startDate')
            ?.setAttribute('min', alternateAddressValues[7])
        if (
          (addUpdateAlternateAddresses[0]?.startDate as string) <= currentDate
        ) {
          const selectedDate = new Date(
            (addUpdateAlternateAddresses[0]?.startDate as string)
              ? (addUpdateAlternateAddresses[0]?.startDate as string)
              : alternateAddressValues[7]
          )
          const minDate = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate() + 2
          )
            .toISOString()
            .split('T')[0]
          document.getElementById('endDate')?.setAttribute('min', minDate)
        } else {
          const selectedDate = new Date(alternateAddressValues[7])
          const minDate = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate() + 2
          )
            .toISOString()
            .split('T')[0]
          document.getElementById('endDate')?.setAttribute('min', minDate)
        }
      }
    }
  }, [alternateAddressValues])

  useEffect(() => {
    addUpdateAddresses
    addUpdateAlternateAddresses
  }, [addUpdateAddresses, addUpdateAlternateAddresses])

  const editFunction = () => {
    approval?.setIsActiveAd(!approval?.isActiveAd)
    AddressFields?.setMailingAddressCollapse(true)
  }

  const removeFunction = () => {
    AddressFields?.setDeleteAlternateAddressData({
      accountId: accountDetails?.accountDetails?.accountId,
      addressId: alternate?.[0].addressId,
      updateBy: authCtx?.account?.name,
    })
  }

  const addAlternateAddress = () => {
    setClicked(true)
    setAlternate([
      {
        addressTypeId: alternate[0]?.addressTypeId,
        addressType: 'MailingAddress',
        title: alternate[0]?.title,
        streetAddress1: alternate[0]?.streetAddress1,
        streetAddress2: alternate[0]?.streetAddress2,
        city: alternate[0]?.city,
        cityName: alternate[0]?.cityName,
        state: alternate[0]?.state,
        stateName: alternate[0]?.stateName,
        country: alternate[0]?.country,
        countryName: alternate[0]?.countryName,
        zipCode: alternate[0]?.zipCode,
      },
    ])
  }

  const showAdditionalButton = () => {
    return (
      <ButtonRow1>
        <SecondaryButtonWrapper1>
          <WDStyledAddAddressButton onClick={() => addAlternateAddress()}>
            <Label
              fontFamily="SourceSansPro-SemiBold"
              font-style="normal"
              font-weight={600}
              font-size="16px"
              line-height="24px"
            >
              {'Additional Alternate Address'}
            </Label>
          </WDStyledAddAddressButton>
        </SecondaryButtonWrapper1>
      </ButtonRow1>
    )
  }

  const notShowAdditionalButton = (): any => {
    //console.log('')
  }

  const collapse = () => {
    AddressFields?.setMailingAddressCollapse(
      !AddressFields?.mailingAddressCollapse
    )
  }

  let merged: AddressList[] = []

  const validateMailing = () => {
    if (
      addUpdateAddresses[0] !== undefined &&
      addUpdateAddressesOriginal[0] !== undefined
    ) {
      const obj1 = addUpdateAddressesOriginal[0]
      const obj2 = addUpdateAddresses[0]
      const values1 = Object.values(obj1)
      const values2 = Object.values(obj2)
      // setMailingChanged(false)
      for (let i = 0; i < values1.length; i++) {
        const value1 = values1[i]
        const value2 = values2[i]
        console.log('value', value2, value1)
        if (typeof value1 === 'string' && value1 !== '' && value2 !== '') {
          if (value1?.trim() !== value2?.trim()) {
            setMailingChanged(true)
            return true
            break
          }
        } else {
          if (value1 !== value2) {
            setMailingChanged(true)
            return true
            break
          }
        }
      }
      return false
    }
  }

  const validateAlternate = () => {
    const obj1 = addUpdateAlternateAddressesOriginal[0]
    const obj2 = addUpdateAlternateAddresses[0]

    const values1 = Object.values(obj1)
    const values2 = Object.values(obj2)
    // setMailingChanged(false)
    for (let i = 0; i < values1.length; i++) {
      const value1 = values1[i]
      const value2 = values2[i]

      if (typeof value1 === 'string') {
        if (value1.trim() !== value2.trim()) {
          setAlternateChanged(true)
          return true
          break
        }
      } else {
        if (value1 !== value2) {
          setAlternateChanged(true)
          return true
          break
        }
      }
    }
    return false
  }

  const validateZipCode = () => {
    if (
      addUpdateAddresses[0]?.zipCode !== undefined &&
      addUpdateAddresses[0]?.countryName === 'United States of America'
    ) {
      if (
        addUpdateAddresses[0]?.stateCode?.toLowerCase() ===
        AddressFields?.addressValidateData?.stateProvince?.toLowerCase() &&
        addUpdateAddresses[0]?.cityName?.toLowerCase() ===
        AddressFields?.addressValidateData?.city?.toLowerCase() &&
        addUpdateAddresses[0]?.zipCode ===
        AddressFields?.addressValidateData?.zipCode
      ) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const validateAlternateZipCode = () => {
    if (
      addUpdateAlternateAddresses[0]?.zipCode !== undefined &&
      // addUpdateAlternateAddresses[0]?.zipCode !== '' &&
      addUpdateAlternateAddresses[0]?.countryName === 'United States of America'
    ) {
      if (
        addUpdateAlternateAddresses[0]?.stateCode?.toLowerCase() ===
        AddressFields?.addressValidateAlternate?.stateProvince?.toLowerCase() &&
        addUpdateAlternateAddresses[0]?.cityName?.toLowerCase() ===
        AddressFields?.addressValidateAlternate?.city?.toLowerCase() &&
        addUpdateAlternateAddresses[0]?.zipCode ===
        AddressFields?.addressValidateAlternate?.zipCode
      ) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  useEffect(() => {
    if (
      (addUpdateAlternateAddresses[0]?.startDate as string) <= currentDate &&
      currentDate <= (addUpdateAlternateAddresses[0]?.endDate as string)
    ) {
      setActiveSection(true)
    } else {
      setActiveSection(false)
    }
  }, [alternateAddressValues, currentDate])

  const checkDateFunc = () => {
    if (alternateChanged === true) {
      if (
        addUpdateAlternateAddresses[0]?.endDate &&
        currentDate <= addUpdateAlternateAddresses[0]?.endDate
      ) {
        setCheckDate(true)
        return true
      } else {
        setCheckDate(false)
        return false
      }
    } else {
      setCheckDate(true)
      return true
    }
  }

  const checkValidStartDateFunc = () => {
    if (
      addUpdateAlternateAddresses[0]?.startDate &&
      addUpdateAlternateAddresses[0]?.endDate &&
      addUpdateAlternateAddresses[0]?.startDate <
      addUpdateAlternateAddresses[0]?.endDate
    ) {
      setCheckStartDate(true)
      return true
    } else {
      setCheckStartDate(false)
      return false
    }
  }

  const validStreet = () => {
    if (addUpdateAddresses[0]?.streetAddress1 === '') {
      setErrorStreetAddress1(true)
      return false
    } else {
      setErrorStreetAddress1(false)
      return true
    }
  }

  const validCity = () => {
    if (addUpdateAddresses[0]?.cityName === '') {
      setErrorCityName(true)
      return false
    } else {
      setErrorCityName(false)
      return true
    }
  }

  const validZip = () => {
    if (addUpdateAddresses[0]?.zipCode === '') {
      setErrorZipCode(true)
      return false
    } else {
      setErrorZipCode(false)
      return true
    }
  }

  const validStreetAlt = () => {
    if (clicked || alternate[0] !== undefined) {
      if (addUpdateAlternateAddresses[0]?.streetAddress1 === '') {
        setErrorStreetAddress1Alternate(true)
        return false
      } else {
        setErrorStreetAddress1Alternate(false)
        return true
      }
    } else {
      setErrorStreetAddress1Alternate(false)
      return true
    }
  }

  const validCityAlternate = () => {
    if (clicked || alternate[0] !== undefined) {
      if (addUpdateAlternateAddresses[0]?.cityName === '') {
        setErrorCityNameAlternate(true)
        return false
      } else {
        setErrorCityNameAlternate(false)
        return true
      }
    } else {
      setErrorCityNameAlternate(false)
      return true
    }
  }

  const validZipAlternate = () => {
    if (clicked || alternate[0] !== undefined) {
      if (addUpdateAlternateAddresses[0]?.zipCode === '') {
        setErrorZipCodeAlternate(true)
        return false
      } else {
        setErrorZipCodeAlternate(false)
        return true
      }
    } else {
      setErrorZipCodeAlternate(false)
      return true
    }
  }

  const checkStartDateError = () => {
    if (alternateChanged) {
      if (
        addUpdateAlternateAddresses[0]?.startDate !== undefined &&
        addUpdateAlternateAddresses[0]?.startDate < currentDate
      ) {
        setStartDateError(true)
        return false
      } else {
        setStartDateError(false)
        return true
      }
    }
    return true
  }
  const checkState = () => {
    if (isP3Account) {
      if (addUpdateAddresses[0]?.stateProvinceName === '') {
        setErrorStateName(true)
        return false
      } else {
        setErrorStateName(false)
        return true
      }
    } else return true
  }

  const checkCountryName = () => {
    const mailChanged = validateMailing()
    console.log('MailChanged', mailChanged, mail[0], addUpdateAddresses[0])
    if (mailChanged) {
      if (isP3Account) {
        if (mail[0] !== undefined) {
          if (mail[0]?.countryName === 'United States of America') {
            if (
              addUpdateAddresses[0]?.countryName === 'United States of America'
            ) {
              approval?.setCountryValid(true)
              return true
            } else {
              approval?.setCountryValid(false)
              return false
            }
          } else if (mail[0]?.countryName !== 'United States of America') {
            approval?.setCountryValid(true)
            return true
          }
        } else {
          approval?.setCountryValid(true)
          return true
        }
        /* if (
          mail[0]?.countryName === 'United States of America'
        ) {
          if (
            addUpdateAddresses[0]?.countryName === 'United States of America'
          ) {
            approval?.setCountryValid(true)
            return true
          } else {
            approval?.setCountryValid(false)
            return false
          }
        } else if (
          mail[0] !== undefined &&
          mail[0]?.countryName !== 'United States of America'
        ) {
          /* approval?.setCountryValid(false)
          return false
        }
        approval?.setCountryValid(true)
        return true */
      } else {
        if (
          (mail[0]?.countryName === 'United States of America' ||
            mail[0]?.countryName === '' ||
            mail[0]?.countryName === null ||
            mail[0] === undefined) &&
          addUpdateAddresses[0]?.countryName === 'United States of America'
        ) {
          approval?.setCountryValid(true)
          return true
        } else {
          approval?.setCountryValid(false)
          return false
        }
      }
    } else {
      approval?.setCountryValid(true)
      return true
    }
  }

  const save = () => {
    if (addUpdateAlternateAddresses[0]?.startDate !== '') {
      checkValidStartDateFunc()
      setValidStartDate(true)
    } else {
      setValidStartDate(false)
    }
    if (addUpdateAlternateAddresses[0]?.endDate !== '') {
      setValidEndDate(true)
    } else {
      setValidEndDate(false)
    }
    const checkValidEndDate = checkDateFunc()
    const validationCheck = validateZipCode()
    const validationAlternateCheck = validateAlternateZipCode()
    const mailChange = validateMailing()
    const alternateChange = validateAlternate()
    const validValueStreetAlt = validStreetAlt()
    const validValueCity = validCityAlternate()
    const validValueZip = validZipAlternate()
    const validMailStreet = validStreet()
    const validMailCity = validCity()
    const validMailZip = validZip()
    const validDate = checkStartDateError()
    const validCountry = checkCountryName()
    // const validState = checkState()
    if (
      addUpdateAddresses[0]?.cityName !== undefined &&
      addUpdateAddresses[0]?.cityName !== ''
    ) {
      const trimmedCity = addUpdateAddresses[0]?.cityName?.trim()
      addUpdateAddresses[0] = {
        ...addUpdateAddresses[0],
        cityName: trimmedCity,
      }
    }
    if (
      addUpdateAlternateAddresses[0]?.cityName !== undefined &&
      addUpdateAlternateAddresses[0]?.cityName !== ''
    ) {
      const trimmedCity = addUpdateAlternateAddresses[0]?.cityName?.trim()
      addUpdateAlternateAddresses[0] = {
        ...addUpdateAlternateAddresses[0],
        cityName: trimmedCity,
      }
    }
    {
      if (mailChange === true) {
        if (alternateChange === true) {
          merged = [...addUpdateAddresses, ...addUpdateAlternateAddresses]
        } else {
          merged = [...addUpdateAddresses]
        }
      } else if (alternateChange === true) {
        merged = [...addUpdateAlternateAddresses]
      } else {
        merged = []
      }
    }
    AddressFields?.setAddressSubmissionPayloadData(merged)
    if (
      validationCheck === true &&
      validationAlternateCheck === true &&
      validValueStreetAlt === true &&
      validValueCity === true &&
      validValueZip === true &&
      validMailStreet === true &&
      validMailCity === true &&
      validMailZip === true &&
      validDate === true &&
      validCountry === true
      // && validState === true
    ) {
      setValid(true)
      setValidAlternate(true)
      if (
        merged.length !== 0 &&
        mailChange === true &&
        alternateChanged === true
      ) {
        if (
          addUpdateAlternateAddresses[0]?.startDate !== '' &&
          addUpdateAlternateAddresses[0]?.endDate !== '' &&
          checkValidEndDate
        ) {
          AddressFields?.setSubmissionPopUp(true)
        }
      } else if (merged.length !== 0 && mailChange) {
        AddressFields?.setSubmissionPopUp(true)
      } else if (
        merged.length !== 0 &&
        alternateChanged &&
        addUpdateAlternateAddresses[0]?.startDate !== '' &&
        addUpdateAlternateAddresses[0]?.endDate !== '' &&
        checkValidEndDate &&
        checkValidStartDateFunc()
      ) {
        AddressFields?.setSubmissionPopUp(true)
      }
    } else if (validationCheck === false) {
      setValid(false)
      AddressFields?.setSubmissionPopUp(false)
    } else if (validationAlternateCheck === false) {
      setValidAlternate(false)
      AddressFields?.setSubmissionPopUp(false)
    } else if (checkValidEndDate === false) {
      AddressFields?.setSubmissionPopUp(false)
    }
  }

  const cancelFunction = () => {
    approval?.setIsActiveAd(!approval?.isActiveAd)
    approval?.setCountryValid(true)
    setValid(true)
    setValidAlternate(true)
    setValidEndDate(true)
    setValidStartDate(true)
    setCheckDate(true)
    setCheckStartDate(true)
    setClicked(false)
    setAlternateChanged(false)
    setValidAlternateZipCode(true)
    setErrorZipCodeAlternate(false)
    setErrorCityNameAlternate(false)
    setErrorStateNameAlternate(false)
    setErrorStreetAddress1Alternate(false)
    setErrorZipCode(false)
    setErrorCityName(false)
    setErrorStreetAddress1(false)
    setStartDateError(false)
    if (addressList !== undefined) {
      const tempOne = addressList?.filter(
        (mailingAddressType) =>
          mailingAddressType.addressType === 'MailingAddress'
      )
      setMail(tempOne)
      const tempTwo = addressList?.filter(
        (mailingAddressType) => mailingAddressType.addressType === 'Alternate'
      )
      setAlternate(tempTwo)
    }

    if (alternate[0]?.addressType === 'MailingAddress') {
      setAlternate([])
    }
  }
  const clearFunction = () => {
    setAddUpdateAlternateAddresses([
      Object.assign(
        {},
        {
          accountId: accountDetails?.accountDetails?.accountId,
          accountNumber: approval?.value,
          addressTypeId: alternate[0]?.addressTypeId,
          addressType: 'Alternate',
          addressId: alternate[0]?.addressId ? alternate[0]?.addressId : 0,
          streetAddress1: '',
          streetAddress2: '',
          streetAddress3: '',
          cityId: 0,
          cityName: '',
          stateProvinceId: 0,
          stateProvinceName: '',
          stateCode: '',
          countrycode: '',
          countryName: '',
          zipCode: '',
          statusId: 0,
          updateBy: authCtx?.account?.name,
          createdBy: authCtx?.account?.name,
          addressNickName: '',
          startDate: '',
          endDate: '',
          createdOn: '2023-06-02 11:37:36.524582+00',
          updateOn: '2023-06-02 11:37:36.524582+00',
        }
      ),
    ])
  }

  const reloadApprovalTrail = () => {
    approval?.retrieveAuditTrail()
    setTimeout(() => {
      approval?.setIsLoading(true)
    }, 200)
    approval?.setIsLoading(false)
  }

  const reloadAuditTrail = () => {
    auditTrailValues?.retrieveAuditTrail()
    setTimeout(() => {
      auditTrailValues?.setIsLoading(true)
    }, 200)
    auditTrailValues?.setIsLoading(false)
  }

  const checkAccountAllowed = () => {
    if (accountDetails?.accountDetails?.isAccountTypeAllowed === false && accountDetails?.accountDetails?.system === 'P3') {
      accountDetails?.setAccountAllowed(false)
      return false
    } else {
      accountDetails?.setAccountAllowed(true)
      return true
    }
  }

  const reloadAddressUI = () => {
    AddressFields?.retrieveMailingAddress()
    AddressFields?.setMailingAddressCollapse(
      AddressFields?.mailingAddressCollapse
    )
    approval?.setIsActiveAd(!approval?.isActiveAd)
  }

  if (addressList === undefined) {
    return null
  }

  if (AddressFields?.isLoading || addressList.length === undefined) {
    return (
      <MasterWrapper>
        <TopRow>
          <HeaderRow>
            <TitleIconWrap>
              <IconWrapper>
                <SvgAddressChange />
              </IconWrapper>
              <WDStyledSectionTitle>{'Mailing Address'}</WDStyledSectionTitle>
            </TitleIconWrap>
          </HeaderRow>
        </TopRow>
        {
          <DividerWrapper>
            <Divider
              bgColor={COLORS.Background.Border}
              horizontal={true}
              thickness="1px"
            />
          </DividerWrapper>
        }
        <LoaderRow>
          <Loader />
        </LoaderRow>
      </MasterWrapper>
    )
  }
  return (
    <MasterWrapper
      id="mailing-address"
      borderShadow={AddressFields?.mailingAddressCollapse ? true : false}
    >
      <TopRow>
        <HeaderRow onClick={() => collapse()}>
          <TitleIconWrap>
            <IconWrapper>
              <SvgAddressChange />
            </IconWrapper>
            <WDStyledSectionTitle>{'Mailing Address'}</WDStyledSectionTitle>
          </TitleIconWrap>
        </HeaderRow>
        <TertiaryButtonWrapper>
          {!approval?.isActiveAd &&
            (approval?.admin ||
              approval?.requester ||
              approval?.requesterAndRestriction ||
              (!approval?.restrictionUser &&
                authCtx?.userRoles?.length !== 0 &&
                !approval?.approver)) && (
              <>
                {!approval?.isActiveAct &&
                  !approval?.isActiveInv &&
                  !approval?.isActiveAl && (
                    /* !checkIsP3Account(
                    accountDetails?.accountDetails?.system as string
                  ) && */ <WDStyledEditButton onClick={() => {
                      const checkIsAccountAllowed = checkAccountAllowed()
                      checkIsAccountAllowed && editFunction()
                    }}>
                      <WDStyledEditButtonLabel>
                        {' '}
                        {'Edit'}
                      </WDStyledEditButtonLabel>
                    </WDStyledEditButton>
                  )}
              </>
            )}
          <EditWrapper onClick={() => collapse()}>
            {AddressFields?.mailingAddressCollapse && <SvgChevronUp />}
            {!AddressFields?.mailingAddressCollapse && <SvgChevronDown />}
          </EditWrapper>
        </TertiaryButtonWrapper>
      </TopRow>
      {AddressFields?.mailingAddressCollapse && (
        <DividerWrapper>
          <Divider
            bgColor={COLORS.Background.Border}
            horizontal={true}
            thickness="1px"
          />
        </DividerWrapper>
      )}
      {AddressFields?.mailingAddressCollapse && (
        <>
          <>
            {approval?.isActiveAd && (
              <React.Fragment>
                <SubSectionWrapper>
                  <WDStyledSubSectionTitle>
                    {'Mailing Address'}
                  </WDStyledSubSectionTitle>
                </SubSectionWrapper>
                <LegalTab>
                  <form ref={formPr}>
                    <Row>
                      <FlexItem1>
                        <InputField
                          label={'Street Address 1*'}
                          name="addressPr"
                          textboxValue={addUpdateAddresses[0]?.streetAddress1}
                          textboxPlaceholder="Address"
                          disabled={!approval?.isActiveAd}
                          onChange={(e) =>
                            handleFormStreetAddressOne(e, 1, 'Mailing')
                          }
                          borderColor={
                            errorStreetAddress1 ? '#BA0517' : '#CBD5E1'
                          }
                          backgroundColor={
                            errorStreetAddress1 ? '#FEF1EE' : '#FFFFFF'
                          }
                        />
                        {errorStreetAddress1 && (
                          <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                            <SvgExclamation />
                            <Label
                              fontFamily={'SourceSansPro-Regular'}
                              fontSize={'14px'}
                              fontStyle={'normal'}
                              fontWeight={400}
                              lineHeight={'16px'}
                              color={'#BA0517'}
                            >
                              {
                                messages.address_ui.error_messages
                                  .required_field
                              }
                            </Label>
                          </InlineErrorWrapper>
                        )}
                      </FlexItem1>
                      <FlexItem1>
                        <InputField
                          label={'Street Address 2'}
                          name="streetaddress2"
                          textboxPlaceholder="Address"
                          textboxDefaultValue={
                            addUpdateAddresses[0]?.streetAddress2
                          }
                          disabled={!approval?.isActiveAd}
                          onChange={(e) =>
                            handleFormStreetAddressTwo(e, 0, 'Mailing')
                          }
                        />
                      </FlexItem1>
                    </Row>
                    <Row>
                      <FlexItem>
                        <InputField
                          label={'City*'}
                          name="cityPr"
                          textboxValue={addUpdateAddresses[0]?.cityName}
                          textboxPlaceholder="City"
                          disabled={!approval?.isActiveAd}
                          onChange={(e) => handleFormCity(e, 0, 'Mailing')}
                          borderColor={
                            !valid || errorCityName ? '#BA0517' : '#CBD5E1'
                          }
                          backgroundColor={
                            !valid || errorCityName ? '#FEF1EE' : '#FFFFFF'
                          }
                        />
                        {(!valid || errorCityName) && (
                          <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                            <SvgExclamation />
                            <Label
                              fontFamily={'SourceSansPro-Regular'}
                              fontSize={'14px'}
                              fontStyle={'normal'}
                              fontWeight={400}
                              lineHeight={'16px'}
                              color={'#BA0517'}
                            >
                              {errorCityName
                                ? messages.address_ui.error_messages
                                  .required_field
                                : !valid
                                  ? messages.address_ui.error_messages
                                    .valid_zip_code_combination
                                  : ''}
                            </Label>
                          </InlineErrorWrapper>
                        )}
                      </FlexItem>
                      <FlexItem>
                        {/* {addUpdateAddresses[0]?.countrycode !== 'US' && (
                          <InputField
                            label={'State*'}
                            name="State"
                            textboxValue={
                              addUpdateAddresses[0]?.stateProvinceName
                            }
                            textboxPlaceholder="State"
                            disabled={!approval?.isActiveAd || !isP3Account}
                            onChange={(e) => handleFormState(e, 0, 'Mailing')}
                            borderColor={errorStateName ? '#BA0517' : '#CBD5E1'}
                            backgroundColor={
                              errorStateName ? '#FEF1EE' : '#FFFFFF'
                            }
                          />
                        )} */}
                        {/* {errorStateName && addUpdateAddresses[0]?.countrycode !== 'US' && (
                          <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                            <SvgExclamation />
                            <Label
                              fontFamily={'SourceSansPro-Regular'}
                              fontSize={'14px'}
                              fontStyle={'normal'}
                              fontWeight={400}
                              lineHeight={'16px'}
                              color={'#BA0517'}
                            >
                              {
                                messages.address_ui.error_messages
                                  .required_field
                              }
                            </Label>
                          </InlineErrorWrapper>
                        )} */}
                        {/* {addUpdateAddresses[0]?.countrycode === 'US' && ( */}
                        <DropdownField
                          label={'State*'}
                          options={statesOptions}
                          value={{
                            value: addUpdateAddresses[0]?.stateCode,
                            label: addUpdateAddresses[0]?.stateProvinceName,
                          }}
                          onChange={(e) => handleFormState(e, 0, 'Mailing')}
                          disabled={
                            !approval?.isActiveAd ||
                            addUpdateAddresses[0]?.countrycode !== 'US'
                          }
                          borderColor={!valid ? '#BA0517' : '#CBD5E1'}
                          backgroundColor={!valid ? '#FEF1EE' : '#FFFFFF'}
                        />
                        {/* )} */}
                        {!valid && (
                          <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                            <SvgExclamation />
                            <Label
                              fontFamily={'SourceSansPro-Regular'}
                              fontSize={'14px'}
                              fontStyle={'normal'}
                              fontWeight={400}
                              lineHeight={'16px'}
                              color={'#BA0517'}
                            >
                              {
                                'Enter a valid Zip Code, State and City combination !'
                              }
                            </Label>
                          </InlineErrorWrapper>
                        )}
                      </FlexItem>
                      <FlexItem>
                        <DropdownField
                          label={'Country*'}
                          value={{
                            value: addUpdateAddresses[0]?.countrycode,
                            label: addUpdateAddresses[0]?.countryName,
                          }}
                          onChange={(e) => handleFormCountry(e, 0, 'Mailing')}
                          disabled={!approval?.isActiveAd}
                          options={countriesOptions}
                        />
                      </FlexItem>
                      <FlexItem>
                        <InputField
                          label={'Zip Code*'}
                          name="zipCodePr"
                          textboxValue={addUpdateAddresses[0]?.zipCode}
                          textboxPlaceholder="Zip Code"
                          disabled={!approval?.isActiveAd}
                          onChange={(e) => handleFormZipCode(e, 0, 'Mailing')}
                          borderColor={
                            !valid || errorZipCode ? '#BA0517' : '#CBD5E1'
                          }
                          backgroundColor={
                            !valid || errorZipCode ? '#FEF1EE' : '#FFFFFF'
                          }
                        />
                        {(!valid || errorZipCode) && (
                          <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                            <SvgExclamation />
                            <Label
                              fontFamily={'SourceSansPro-Regular'}
                              fontSize={'14px'}
                              fontStyle={'normal'}
                              fontWeight={400}
                              lineHeight={'16px'}
                              color={'#BA0517'}
                            >
                              {errorZipCode
                                ? messages.address_ui.error_messages
                                  .required_field
                                : !valid
                                  ? messages.address_ui.error_messages
                                    .valid_zip_code_combination
                                  : ''}
                            </Label>
                          </InlineErrorWrapper>
                        )}
                        {!validZipCode && (
                          <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                            <SvgExclamation />
                            <Label
                              fontFamily={'SourceSansPro-Regular'}
                              fontSize={'14px'}
                              fontStyle={'normal'}
                              fontWeight={400}
                              lineHeight={'16px'}
                              color={'#BA0517'}
                            >
                              {'Enter valid Zip code, 5 digits are allowed !'}
                            </Label>
                          </InlineErrorWrapper>
                        )}
                      </FlexItem>
                    </Row>
                  </form>
                </LegalTab>
              </React.Fragment>
            )}
          </>
          {/* Read Only Mode Legal Part Legal Address */}
          {mail[0] !== undefined && !approval?.isActiveAd && (
            <>
              <SubSectionWrapper>
                <WDStyledSubSectionTitle>
                  {'Mailing Address'}
                </WDStyledSubSectionTitle>
              </SubSectionWrapper>
              <React.Fragment>
                <Row>
                  {address_length.map((item, index) => {
                    return (
                      <FlexItem2 key={index}>
                        <LabelWrap>
                          <WDReadModeTitle>
                            {address_keysPr[index]}
                          </WDReadModeTitle>
                        </LabelWrap>
                        <LabelWrap>
                          <WDReadModeValue>
                            {address_valuesPr[index] ? (
                              address_valuesPr[index]
                            ) : (
                              <span style={{ paddingLeft: '15px' }}>
                                {'--'}
                              </span>
                            )}
                          </WDReadModeValue>
                        </LabelWrap>
                      </FlexItem2>
                    )
                  })}
                </Row>
              </React.Fragment>
              <React.Fragment>
                <Row>
                  {!approval?.isActiveAd &&
                    address_lengthPr.map((item, index) => {
                      return (
                        <FlexItem key={index}>
                          <LabelWrap>
                            <WDReadModeTitle>
                              {address_keysPr[index + 2]}
                            </WDReadModeTitle>
                          </LabelWrap>
                          <LabelWrap>
                            <WDReadModeValue>
                              {address_valuesPr[index + 2] ? (
                                address_valuesPr[index + 2]
                              ) : (
                                <span style={{ paddingLeft: '15px' }}>
                                  {'--'}
                                </span>
                              )}
                            </WDReadModeValue>
                          </LabelWrap>
                        </FlexItem>
                      )
                    })}
                </Row>
              </React.Fragment>
            </>
          )}
          {mail.length === 0 && (
            <>
              <React.Fragment>
                {!approval?.isActiveAd &&
                  AddressFields?.mailingAddressCollapse && (
                    <StyledHeadWrapper>
                      <WDInputLabel>
                        {
                          'Hey! There is no address associated to this account. Please click on edit to add address.'
                        }
                      </WDInputLabel>
                    </StyledHeadWrapper>
                  )}
              </React.Fragment>
            </>
          )}
          {alternate.length > 0 && (
            <DividerWrapper2>
              <Divider
                bgColor={COLORS.Background.Border}
                horizontal={true}
                thickness="1px"
              />
            </DividerWrapper2>
          )}

          {alternate.length > 0 && (
            <>
              <SubSectionWrapper>
                <WDStyledSubSectionTitle>
                  {'Alternate/Temporary Address'}
                </WDStyledSubSectionTitle>
                {!clicked && (
                  <>
                    {activeSection ? (
                      <StyledActiveButtonLabel>
                        {'(Active)'}
                      </StyledActiveButtonLabel>
                    ) : (
                      <StyledActiveButtonLabel>
                        {'(InActive)'}
                      </StyledActiveButtonLabel>
                    )}
                  </>
                )}
                {approval?.isActiveAd && (
                  <ButtonRow>
                    {!showSection && (
                      <Button
                        onClick={() => {
                          removeFunction()
                        }}
                        prefixedIcon="true"
                        color={''}
                        svg={<SvgRecycle />}
                      >
                        Void Temporary Address
                      </Button>
                    )}
                    <WDStyledCancelButton onClick={() => clearFunction()}>
                      <WDStyledCancelButtonLabel>
                        {'Clear'}
                      </WDStyledCancelButtonLabel>
                    </WDStyledCancelButton>
                  </ButtonRow>
                )}
              </SubSectionWrapper>
              {/* {approval?.isActiveAd  && (
            <ButtonRow1>
              <SecondaryButtonWrapper1>
                <WDStyledAddAddressButton
                  onClick={() => setAlternateSection(true)}
                >
                  <Label
                    fontFamily="SourceSansPro-SemiBold"
                    font-style="normal"
                    font-weight={600}
                    font-size="16px"
                    line-height="24px"
                  >
                    {'Additional Alternate Address'}
                  </Label>
                </WDStyledAddAddressButton>
              </SecondaryButtonWrapper1>
            </ButtonRow1>
          )} */}

              {approval?.isActiveAd && (
                // <AlternateTab>
                //   <form ref={formPr}>
                <React.Fragment>
                  <Row>
                    <FlexItem1>
                      <InputField
                        label={'Nickname'}
                        name="nickname"
                        textboxValue={
                          addUpdateAlternateAddresses[0]?.addressNickName
                        }
                        textboxPlaceholder="Nickname"
                        disabled={!approval?.isActiveAd}
                        onChange={(e) =>
                          handleAddressNickName(e, 0, 'Alternate')
                        }
                      />
                    </FlexItem1>
                    <FlexItem1></FlexItem1>
                  </Row>
                  <Row>
                    <FlexItem1>
                      <InputField
                        label={'Street Address 1*'}
                        name="addressPr"
                        textboxValue={
                          addUpdateAlternateAddresses[0]?.streetAddress1
                        }
                        textboxPlaceholder="Address"
                        disabled={!approval?.isActiveAd}
                        onChange={(e) =>
                          handleFormStreetAddressOne(e, 0, 'Alternate')
                        }
                        borderColor={
                          errorStreetAddress1Alternate ? '#BA0517' : '#CBD5E1'
                        }
                        backgroundColor={
                          errorStreetAddress1Alternate ? '#FEF1EE' : '#FFFFFF'
                        }
                      />
                      {errorStreetAddress1Alternate && (
                        <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                          <SvgExclamation />
                          <Label
                            fontFamily={'SourceSansPro-Regular'}
                            fontSize={'14px'}
                            fontStyle={'normal'}
                            fontWeight={400}
                            lineHeight={'16px'}
                            color={'#BA0517'}
                          >
                            {messages.address_ui.error_messages.required_field}
                          </Label>
                        </InlineErrorWrapper>
                      )}
                    </FlexItem1>
                    <FlexItem1>
                      <InputField
                        label={'Street Address 2'}
                        name="streetaddress2"
                        textboxPlaceholder="Address"
                        textboxValue={
                          addUpdateAlternateAddresses[0]?.streetAddress2
                        }
                        disabled={!approval?.isActiveAd}
                        onChange={(e) =>
                          handleFormStreetAddressTwo(e, 0, 'Alternate')
                        }
                      />
                    </FlexItem1>
                  </Row>
                  <Row>
                    <FlexItem>
                      <InputField
                        label={'City*'}
                        name="cityPr"
                        textboxValue={addUpdateAlternateAddresses[0]?.cityName}
                        textboxPlaceholder="City"
                        disabled={!approval?.isActiveAd}
                        onChange={(e) => handleFormCity(e, 0, 'Alternate')}
                        borderColor={
                          !validAlternate || errorCityNameAlternate
                            ? '#BA0517'
                            : '#CBD5E1'
                        }
                        backgroundColor={
                          !validAlternate || errorCityNameAlternate
                            ? '#FEF1EE'
                            : '#FFFFFF'
                        }
                      />
                      {(!validAlternate || errorCityNameAlternate) && (
                        <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                          <SvgExclamation />
                          <Label
                            fontFamily={'SourceSansPro-Regular'}
                            fontSize={'14px'}
                            fontStyle={'normal'}
                            fontWeight={400}
                            lineHeight={'16px'}
                            color={'#BA0517'}
                          >
                            {errorCityNameAlternate
                              ? messages.address_ui.error_messages
                                .required_field
                              : !validAlternate
                                ? messages.address_ui.error_messages
                                  .valid_zip_code_combination
                                : ''}
                          </Label>
                        </InlineErrorWrapper>
                      )}
                    </FlexItem>
                    <FlexItem>
                      <DropdownField
                        label={'State*'}
                        options={statesOptions}
                        value={{
                          value: addUpdateAlternateAddresses[0]?.stateCode,
                          label:
                            addUpdateAlternateAddresses[0]?.stateProvinceName ??
                            '',
                        }}
                        onChange={(e) => handleFormState(e, 0, 'Alternate')}
                        disabled={!approval?.isActiveAd}
                        borderColor={
                          !validAlternate || errorStateNameAlternate
                            ? '#BA0517'
                            : '#CBD5E1'
                        }
                        backgroundColor={
                          !validAlternate || errorStateNameAlternate
                            ? '#FEF1EE'
                            : '#FFFFFF'
                        }
                      />
                      {(!validAlternate || errorStateNameAlternate) && (
                        <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                          <SvgExclamation />
                          <Label
                            fontFamily={'SourceSansPro-Regular'}
                            fontSize={'14px'}
                            fontStyle={'normal'}
                            fontWeight={400}
                            lineHeight={'16px'}
                            color={'#BA0517'}
                          >
                            {errorStateNameAlternate
                              ? messages.address_ui.error_messages
                                .required_field
                              : !validAlternate
                                ? messages.address_ui.error_messages
                                  .valid_zip_code_combination
                                : ''}
                          </Label>
                        </InlineErrorWrapper>
                      )}
                    </FlexItem>
                    <FlexItem>
                      <DropdownField
                        label={'Country*'}
                        value={{
                          value: 'US',
                          label: 'United States of America',
                        }}
                        onChange={(e) => handleFormCountry(e, 0, 'Alternate')}
                        disabled={true}
                        options={countriesOptions}
                      />
                    </FlexItem>
                    <FlexItem>
                      <InputField
                        label={'Zip Code*'}
                        name="zipCodePr"
                        textboxValue={addUpdateAlternateAddresses[0]?.zipCode}
                        textboxPlaceholder="Zip Code"
                        disabled={!approval?.isActiveAd}
                        onChange={(e) => handleFormZipCode(e, 0, 'Alternate')}
                        borderColor={
                          !validAlternate || errorZipCodeAlternate
                            ? '#BA0517'
                            : '#CBD5E1'
                        }
                        backgroundColor={
                          !validAlternate || errorZipCodeAlternate
                            ? '#FEF1EE'
                            : '#FFFFFF'
                        }
                      />
                      {(!validAlternate || errorZipCodeAlternate) && (
                        <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                          <SvgExclamation />
                          <Label
                            fontFamily={'SourceSansPro-Regular'}
                            fontSize={'14px'}
                            fontStyle={'normal'}
                            fontWeight={400}
                            lineHeight={'16px'}
                            color={'#BA0517'}
                          >
                            {errorZipCodeAlternate
                              ? messages.address_ui.error_messages
                                .required_field
                              : !validAlternate
                                ? messages.address_ui.error_messages
                                  .valid_zip_code_combination
                                : ''}
                          </Label>
                        </InlineErrorWrapper>
                      )}
                      {!validAlternateZipCode && (
                        <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                          <SvgExclamation />
                          <Label
                            fontFamily={'SourceSansPro-Regular'}
                            fontSize={'14px'}
                            fontStyle={'normal'}
                            fontWeight={400}
                            lineHeight={'16px'}
                            color={'#BA0517'}
                          >
                            {'Enter valid Zip code, 5 digits are allowed !'}
                          </Label>
                        </InlineErrorWrapper>
                      )}
                    </FlexItem>
                  </Row>
                  <Row>
                    <FlexItem2>
                      <InputField
                        label={'Start Date*'}
                        name="startDate"
                        type="Date"
                        textboxValue={addUpdateAlternateAddresses[0]?.startDate}
                        disabled={!approval?.isActiveAd}
                        onChange={(e) => handleStartDate(e, 0, 'Alternate')}
                        id="startDate"
                        borderColor={
                          !validStartDate || !checkStartDate || startDateError
                            ? '#BA0517'
                            : '#CBD5E1'
                        }
                        backgroundColor={
                          !validStartDate || !checkStartDate || startDateError
                            ? '#FEF1EE'
                            : '#FFFFFF'
                        }
                      />
                      {(!validStartDate ||
                        !checkStartDate ||
                        startDateError) && (
                          <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                            <SvgExclamation />
                            <Label
                              fontFamily={'SourceSansPro-Regular'}
                              fontSize={'14px'}
                              fontStyle={'normal'}
                              fontWeight={400}
                              lineHeight={'16px'}
                              color={'#BA0517'}
                            >
                              {!validStartDate
                                ? messages.address_ui.error_messages
                                  .valid_start_date
                                : !checkStartDate
                                  ? messages.address_ui.error_messages
                                    .start_date_after_end_date
                                  : startDateError
                                    ? messages.address_ui.error_messages
                                      .start_date_before_today
                                    : ''}
                            </Label>
                          </InlineErrorWrapper>
                        )}
                    </FlexItem2>
                    <FlexItem2>
                      <InputField
                        label={'End Date*'}
                        name="endDate"
                        type="Date"
                        textboxValue={addUpdateAlternateAddresses[0]?.endDate}
                        disabled={!approval?.isActiveAd}
                        onChange={(e) => handleEndDate(e, 0, 'Alternate')}
                        id="endDate"
                        borderColor={
                          !validEndDate || (!checkDate && alternateChanged)
                            ? '#BA0517'
                            : '#CBD5E1'
                        }
                        backgroundColor={
                          !validEndDate || (!checkDate && alternateChanged)
                            ? '#FEF1EE'
                            : '#FFFFFF'
                        }
                      />
                      {(!validEndDate || (!checkDate && alternateChanged)) && (
                        <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                          <SvgExclamation />
                          <Label
                            fontFamily={'SourceSansPro-Regular'}
                            fontSize={'14px'}
                            fontStyle={'normal'}
                            fontWeight={400}
                            lineHeight={'16px'}
                            color={'#BA0517'}
                          >
                            {!validEndDate
                              ? messages.address_ui.error_messages
                                .valid_end_date
                              : !checkDate && alternateChanged
                                ? messages.address_ui.error_messages
                                  .end_Date_after_today
                                : ''}
                          </Label>
                        </InlineErrorWrapper>
                      )}
                    </FlexItem2>
                  </Row>
                </React.Fragment>
                //   </form>
                // </AlternateTab>
              )}
            </>
          )}

          {/* Read Only Mode Legal Part Alternate Address */}

          {!approval?.isActiveAd && alternate.length > 0 && (
            <>
              <React.Fragment>
                {!approval?.isActiveAd && (
                  <>
                    <Row>
                      <FlexItem2>
                        <LabelWrap>
                          <WDReadModeTitle>Nickname</WDReadModeTitle>
                        </LabelWrap>
                        <LabelWrap>
                          <WDReadModeValue>
                            {alternate[0]?.addressNickName ? (
                              alternate[0]?.addressNickName
                            ) : (
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;{'--'}</span>
                            )}
                          </WDReadModeValue>
                        </LabelWrap>
                      </FlexItem2>
                      <FlexItem2></FlexItem2>
                    </Row>
                    <Row>
                      {address_length.map((item, index) => {
                        return (
                          <FlexItem2 key={index}>
                            <LabelWrap>
                              <WDReadModeTitle>
                                {address_keysPr[index]}
                              </WDReadModeTitle>
                            </LabelWrap>
                            <LabelWrap>
                              <WDReadModeValue>
                                {alternateAddressValues[index + 1] ? (
                                  alternateAddressValues[index + 1]
                                ) : (
                                  <span style={{ paddingLeft: '15px' }}>
                                    {'--'}
                                  </span>
                                )}
                              </WDReadModeValue>
                            </LabelWrap>
                          </FlexItem2>
                        )
                      })}
                    </Row>
                  </>
                )}
              </React.Fragment>
              <React.Fragment>
                <Row>
                  {!approval?.isActiveAd &&
                    alternate?.length > 0 &&
                    address_lengthPr.map((item, index) => {
                      return (
                        <FlexItem key={index}>
                          <LabelWrap>
                            <WDReadModeTitle>
                              {address_keysPr[index + 2]}
                            </WDReadModeTitle>
                          </LabelWrap>
                          <LabelWrap>
                            <WDReadModeValue>
                              {alternateAddressValues[index + 3] ? (
                                alternateAddressValues[index + 3]
                              ) : (
                                <span style={{ paddingLeft: '15px' }}>
                                  {'--'}
                                </span>
                              )}
                            </WDReadModeValue>
                          </LabelWrap>
                        </FlexItem>
                      )
                    })}
                </Row>
              </React.Fragment>
              <React.Fragment>
                {!approval?.isActiveAd && alternate.length > 0 && (
                  <Row>
                    {address_length.map((item, index) => {
                      return (
                        <FlexItem2 key={index}>
                          <LabelWrap>
                            <WDReadModeTitle>
                              {address_keysPr[index + 6]}
                            </WDReadModeTitle>
                          </LabelWrap>
                          <LabelWrap>
                            <WDReadModeValue>
                              {alternateAddressValues[index + 7] ? (
                                alternateAddressValues[index + 7]
                              ) : (
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'--'}
                                </span>
                              )}
                            </WDReadModeValue>
                          </LabelWrap>
                        </FlexItem2>
                      )
                    })}
                  </Row>
                )}
              </React.Fragment>
            </>
          )}

          {approval?.isActiveAd && (
            <React.Fragment>
              {alternate.length > 0
                ? notShowAdditionalButton()
                : checkIsP3Account(
                  accountDetails?.accountDetails?.system as string
                )
                  ? ''
                  : showAdditionalButton()}
              <DividerWrapper2>
                <Divider
                  bgColor={COLORS.Background.Border}
                  horizontal={true}
                  thickness="1px"
                />
              </DividerWrapper2>
              <ButtonRow>
                <SecondaryButtonWrapper>
                  <WDStyledCancelButton onClick={() => cancelFunction()}>
                    <WDStyledCancelButtonLabel>
                      {'Cancel'}
                    </WDStyledCancelButtonLabel>
                  </WDStyledCancelButton>
                </SecondaryButtonWrapper>
                <PrimaryButtonWrapper>
                  <WDStyledSaveButton
                    onClick={() => save()}
                  >
                    <WDStyledCancelButtonLabel>
                      {'Save'}
                    </WDStyledCancelButtonLabel>
                  </WDStyledSaveButton>
                </PrimaryButtonWrapper>
              </ButtonRow>
            </React.Fragment>
          )}
        </>
      )}

      {err === undefined || err === 100 ? null : err === 200 ? (
        <ErrorModal
          success={200}
          onClick={() => {
            AddressFields?.setErrorMsg(100)
            reloadApprovalTrail()
            reloadAuditTrail()
            reloadAddressUI()
          }}
          successMsg={
            'This request has been submitted for review and approval.'
          }
        />
      ) : (
        <ErrorModal
          onClick={() => {
            AddressFields?.setErrorMsg(100)
          }}
          success={500}
        />
      )}
      {delError === undefined || delError === 100 ? null : delError === 200 ? (
        <ErrorModal
          success={200}
          onClick={() => {
            AddressFields?.setDeleteErrorMsg(100)
            reloadAddressUI()
            reloadAuditTrail()
          }}
          successMsg={
            'Action has been completed. Selected Address has been deleted.'
          }
        />
      ) : (
        <ErrorModal
          onClick={() => {
            AddressFields?.setDeleteErrorMsg(100)
          }}
          success={500}
          blsErrorMsg={AddressFields?.blsError}
        />
      )}
    </MasterWrapper>
  )
}
