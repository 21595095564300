import { useContext, useState } from 'react'
import { SidebarMenu } from '../../components/sidebarMenu/SidebarMenu'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'
import { AccountRestrictionsContext } from '../../services/accountRestrictionsContext/AccountRestrictionsContext '
import { AccountRolesContext } from '../../services/accountRolesContext/AccountRolesContext'
import { AuditTrailContext } from '../../services/auditTrailContext/AuditTrailContext'
import { HouseholdContext } from '../../services/householdContext/HouseholdContext'
import { InvestmentContext } from '../../services/investmentContext/InvestmentContext'
import { MailingAddressContext } from '../../services/mailingAddressContext/MailingAddressContext'
import { PaperlessContext } from '../../services/paperlessContext/PaperlessContext'
import { SidebarMenuListProps } from './ISidebarMwnuList'
import { SidebarMenuListWrapper, StyledAnchor } from './styles'
import  {AccountRestrictionReportsContext}  from '../../services/accountRestrictionReports/AccountRestrictionReportsContext'
import { AuthContext } from '../../services/AuthContext'
import { AccountLoanDetailsContext } from '../../services/accountLoanDetailsContext/AccountLoanDetailsContext'
import { BeneficiaryContext } from '../../services/beneficiaryContext/BeneficiaryContext'

export const SidebarMenuList = ({ items }: SidebarMenuListProps) => {
  const [title, setTitle] = useState('')
  const authCtx = useContext(AuthContext)
  const accountDetailsContext = useContext(AccountDetailsContext)
  const mailingAddressContext = useContext(MailingAddressContext)
  const householdContext = useContext(HouseholdContext)
  const accountRolesContext = useContext(AccountRolesContext)
  const auditTrailContext = useContext(AuditTrailContext)
  const paperlessContext = useContext(PaperlessContext)
  const investmentContext = useContext(InvestmentContext)
  const beneficiaryContext = useContext(BeneficiaryContext)
  const accountRestrictionsContext = useContext(AccountRestrictionsContext)
  const accountLoanDetailContext = useContext(AccountLoanDetailsContext)
  const accountRestrictionReportsContext = useContext(AccountRestrictionReportsContext)


  const handleClick = (item: string) => {
    setTitle(item)
    if (item == 'Account Details') {
      accountDetailsContext?.setAccountDetailsCollapse(true)
      accountRestrictionsContext?.setAccountRestrictonsCollapse(false)
    }
    if (item == 'Mailing Address') {
      mailingAddressContext?.setMailingAddressCollapse(true)
      accountRestrictionsContext?.setAccountRestrictonsCollapse(false)
    }
    if (item == 'Household') {
      householdContext?.setHouseholdCollapse(true)
      accountRestrictionsContext?.setAccountRestrictonsCollapse(false)
    }
    if (item == 'Account Roles') {
      accountRolesContext?.setAccountRolesCollapse(true)
      accountRestrictionsContext?.setAccountRestrictonsCollapse(false)
    }
    if (item == 'Audit Trail') {
      auditTrailContext?.setAuditTrailCollapse(true)
      accountRestrictionsContext?.setAccountRestrictonsCollapse(false)
    }
    if (item == 'Paperless') {
      paperlessContext?.setPaperlessCollapse(true)
      accountRestrictionsContext?.setAccountRestrictonsCollapse(false)
    }
    if (item == 'Investment Profile') {
      investmentContext?.setInvestmentCollapse(true)
      accountRestrictionsContext?.setAccountRestrictonsCollapse(false)
    }

    if (item == 'Beneficiary Designation') {
      beneficiaryContext?.setBeneficiaryCollapse(true)
      accountRestrictionsContext?.setAccountRestrictonsCollapse(false)
    }
    
    if (item == 'Account Restrictions') {
      accountRestrictionsContext?.setAccountRestrictonsCollapse(true)
    }
    if (item == 'Account Loan Detail') {
      accountLoanDetailContext?.setAccountLoanDetailsCollapse(true)
      accountRestrictionsContext?.setAccountRestrictonsCollapse(false)
      }
    if (item == 'Account Restrictions Report') {
      accountRestrictionsContext?.setAccountRestrictonsCollapse(false)
      accountRestrictionReportsContext?.setAccountRestrictionReportsCollapse(true)
    }
  }

  return (
    <SidebarMenuListWrapper>
      {items.map((item, index) => {
        return (
          <StyledAnchor
            href={'#' + item?.toLowerCase().replace(/\s/g, '-')}
            key={index}
            onClick={() => handleClick(item)}
          >
            <SidebarMenu name={item} itemTitle={title} />
          </StyledAnchor>
        )
      })}
    </SidebarMenuListWrapper>
  )
}
