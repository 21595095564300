import styled from 'styled-components'
import { COLORS } from "../../theme/Colors";

export const Row = styled.div`
display: flex;
flex: 0 1 100%;
align-self: stretch;
width: 100%;
gap: 24px;
margin: 8px 0px;
`

export const Box = styled.div`
border: 1px solid #d3d3d3;
`

export const LabelWrap = styled.div`
  display: flex;
  align-items: end;
  gap: 8px;
  padding: 4px 0px;
  color: blue;
  font-size: small;
`

export const LabelWrap3 = styled.div`
  color: black;
  padding-top: 30px;
  padding-right: 3px;
`

export const FlexItem = styled.div`
  flex: 0 1 17%;
  width: 17.6%
`

export const FlexItem2 = styled.div`
flex: 0 1 50%;
width: 50%;
`

export const FlexItem3 = styled.div`
  display: flex;
  flex: 0 1 21.6%;
  justify-content: end;
  margin-left: auto;
  width: 21.6%
  padding: 0px
  line-height: 6px !important;
  padding-right: 3px;   
`

export const MandatoryLabel = styled.div`
  color: ${COLORS.Text.Danger};
`

export const LabelWrap2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
interface AddressDetailsUIProps {
  borderShadow?: boolean
}

const borderShadowProperty = '0px 2px 6px -2px rgba(0, 36, 93, 0.3)'

export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex: 0 1 100%;
  width: 100%;
  padding: 8px 24px;
  margin-bottom: 16px;
  margin-left: 24px;
  /* padding-top: 32px;
padding-bottom: 32px;
gap: 1 rem; */
  background: ${COLORS.Background.Primary};
  /* neutral/border-weak */

  border: 1px solid #e2e8f0;
  /* Elevation/Elevation-low */

  box-shadow: ${(AddressProps: AddressDetailsUIProps) =>
    AddressProps.borderShadow ? borderShadowProperty : null};
  border-radius: 8px;
`


export const TitleIconWrap = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 10px;
`

export const HeaderRow = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
  max-height: 48px;
  gap: 16px;
  margin: 0px;
  cursor: pointer;
`



export const LoaderRow = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  // max-height: 84px;
  gap: 24px;
  margin: 8px 0px;
`

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 84px;
  gap: 16px;
  // margin: 8px 0px;
  padding-top: 16px;
  padding-bottom: 16px;
`

export const Individual = styled.div``


export const PrimaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 6.0648px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const SecondaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 110px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const TertiaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 92px;
  min-height: 32px;
  align-items: center;
  justify-content: flex-end;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-self: flex-end;
`

export const IconWrapper = styled.div`
  /* padding-top: 0.5rem; */
`
export const EditWrapper = styled.div`
  padding-left:10px
`


export const TopRow = styled.div`
  /* padding-top: 0.5rem; */
  display: flex;
  max-height: 48px;
  padding-bottom: 8px;
  width: 100%;
  margin: 0px;
  cursor: pointer;
`

export const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  /* margin-bottom: 16px; */
`

export const DividerWrapper2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 16px 0px 0px 0px;
`
export const ModalWrapper = styled.div`
  position: fixed;
  /* overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  } */
  z-index: 1;
  // overflow-y: scroll;
  display: flex;
  height:100%;
  position: fixed;
  padding-top: 24px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* padding-bottom: 30px; */
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 116, 139, 0.8);
  width: 100%;
  // margin: 0 -5%;
`
export const NotesWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 100px;
  gap: 4px;
  margin: 8px 0px;
`

export const ContactPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  height: 530px !important;
  position: absolute;
  flex: 0 1 100%;
  width: 62.77%;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  margin-top: 1rem;
  background: ${COLORS.Background.Primary};
  border: 1px solid #e2e8f0;
  box-shadow: none;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: scroll;
  }
`
// ${(ContactUIProps: PartyUIProps) =>
//   ContactUIProps.borderShadow ? borderShadowProperty : null}
export const SubHeadingWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 10px;
  gap: 8px;
  margin: 8px 0px;
  `

export const StyledTableContainer = styled.div`
  width: 100%;
  /* padding: 24px; */
  padding: 0px 0px 16px;
  margin-top: 16px;
  background: '#FFFFFF';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* border: 1px solid #cbd5e1;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px; */
  border-top: none;
  table {
    tbody {
      tr {
        text-align: left;
        td {
          padding: 8px;
          font-family: SourceSansPro-Regular !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #0f172a;
          :nth-child(1) {
            padding: 8px;
            font-family: SourceSansPro-Regular !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 16px !important;
          }
        }
      }
    }

    thead {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #0f172a;
      tr {
        th {
          padding: 4px 0px 4px 8px;
          font-family: SourceSansPro-Bold !important;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #0f172a;
          width: 20%;
        }
      }
    }
  }
`
export const CloseWrapper = styled.div`
  cursor: pointer;
`
export const CloseWrapper1 = styled.div`
  cursor: pointer;
  margin-top :18px;
`