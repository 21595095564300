import { useContext, useEffect, useState } from 'react'
import {
  ApprovedWrapper,
  CancelWrapper,
  CancelWrapperLeft,
  CancelWrapperRight,
  ChangeTextWrapper,
  InputFieldWrapper,
  PopUpTextWrapper,
  RootContainer,
  Row,
} from './styles'
import { ApprovalContext } from '../../../services/approvalContext/ApprovalContext'
import { SvgCheck } from '../../../components/svg/SvgCheck'
import {
  WDStyledCancelButtonLabel,
  WDStyledCountryLabel,
  WDStyledToastLabel,
  WDTableLabel,
} from '../../../components/ui/WDTypography'
import { SvgCancel } from '../../../components/svg/SvgCancel'
import { InputField } from '../../../composites/inputField/InputField'
import { AiwContext } from '../../../services/aiwContext/AiwContext'
import { SvgExclamation } from '../../../components/svg/SvgExclamation'
import { AccountDetailsContext } from '../../../services/accountDetailsContext/AccountDetailsContext'
import { checkIsP3Account } from '../../../shared/helper'

const HeaderContent = () => {
  const approval = useContext(ApprovalContext)
  const accountDetails = useContext(AccountDetailsContext)
  const isP3Account = checkIsP3Account(
    accountDetails?.accountDetails?.system as string
  )
  const electron = useContext(AiwContext)
  const err = approval?.errorMsg

  useEffect(() => {
    setTimeout(() => {
      approval?.setApproved(false)
    }, 10000)
  }, [approval])
  const UpdatedValue = (e: string) => {
    approval?.setValue(e)
  }

  return (
    <RootContainer>
      <InputFieldWrapper>
        <InputField
          // label={'Client Id'}
          name={'ClientID'}
          textboxValue={approval?.value}
          onChange={(e) => {
            if (electron.inputValue.input !== '') {
              console.log()
            } else {
              UpdatedValue(e)
            }
          }}
        />
      </InputFieldWrapper>
      {accountDetails?.accountDetails?.accountNumber && accountDetails?.accountAllowed === false && (
        <CancelWrapper>
          <CancelWrapperLeft>
            <SvgExclamation />
            <WDStyledCountryLabel>
              {
                `${accountDetails?.accountDescription ? accountDetails?.accountDescription :'Account Type'} - Account update not supported at this time. Please contact New Accounts Department to Update manually.`
              }
            </WDStyledCountryLabel>
          </CancelWrapperLeft>
          <CancelWrapperRight>
            <PopUpTextWrapper onClick={() => {
              accountDetails?.setAccountAllowed(true)
            }}>
              <SvgCancel />
            </PopUpTextWrapper>
          </CancelWrapperRight>
        </CancelWrapper>
      )}
      {!approval?.countryValid && !isP3Account && (
        <CancelWrapper>
          <CancelWrapperLeft>
            <SvgExclamation />
            <WDStyledCountryLabel>
              {
                'Updating Foreign Addresses are not programmatically supported at this time.  Please contact New Accounts Department to Update manually.'
              }
            </WDStyledCountryLabel>
          </CancelWrapperLeft>
          <CancelWrapperRight>
            <PopUpTextWrapper onClick={() => approval?.setCountryValid(true)}>
              <SvgCancel />
            </PopUpTextWrapper>
          </CancelWrapperRight>
        </CancelWrapper>
      )}
      {!approval?.countryValid && isP3Account && (
        <CancelWrapper>
          <CancelWrapperLeft>
            <SvgExclamation />
            <WDStyledCountryLabel>
              {
                'Updating Foreign Address are not programmatically supported at this time.  Please contact New Accounts Department to Update manually.'
              }
            </WDStyledCountryLabel>
          </CancelWrapperLeft>
          <CancelWrapperRight>
            <PopUpTextWrapper onClick={() => approval?.setCountryValid(true)}>
              <SvgCancel />
            </PopUpTextWrapper>
          </CancelWrapperRight>
        </CancelWrapper>
      )}
    </RootContainer>
  )
}

export default HeaderContent
