import { CSSObjectWithLabel, StylesConfig, ValueContainerProps } from "react-select";
import { StyledSelect } from "./styles";
import { SelectDropdownProps } from "./ISelectDropdown";
import { COLORS } from "../../theme/Colors";

const SelectDropdown = ({
  name,
  onChange,
  defaultValue,
  options,
  isDisabled,
  isSearchable,
  width,
  borderRadius,
  padding,
  fs,
  fw,
  lh,
  ff,
  chevronFill,
  borderColor,
  selectedValueColor,
  backgroundColor,
  height,
  value,
}: SelectDropdownProps) => {
  const customStyles = {
    control: () => ({
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "8px",
      // width: '176px',
      padding: padding,
      borderRadius: borderRadius,
      border: "1px solid " + (borderColor? borderColor : `${COLORS.UI.Border}`),
      cursor: "pointer",
      fontSize: fs,
      fontWeight: fw,
      lineHeight: lh,
      fontFamily: ff,
      innerHeight: height,
      background: backgroundColor? (isDisabled? `${COLORS.UI.NeutralBorderWeak}` : backgroundColor) : (isDisabled? "#E2E8F0" : "#FFFFFF"),
    }),

    option: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: "flex",
      padding: "8px 16px",
      cursor: "pointer",
    }),

    singleValue: (provided: CSSObjectWithLabel) => {
      const opacity = isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const display = "flex";
      const alignItems = "center";
      const justifyContent = "space-between";
      const color = selectedValueColor;
      const padding = 0;

      return {
        ...provided,
        opacity,
        transition,
        display,
        alignItems,
        justifyContent,
        color,
        padding,
      };
    },

    valueContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
      textOverflow: "ellipsis",
      overflow: "hidden",
      padding: "8px 16px",
      margin: "0px !important",
      fontSize: fs,
      fontWeight: fw,
      lineHeight: lh,
      fontFamily: 'SourceSansPro-Regular',
    }), 

    dropdownIndicator: () => {
      const color = isDisabled? "#E2E8F0" : chevronFill;
      const display = "flex";
      const width = "16px";
      const height = "16px";
      const alignItems = "center";

      return { color, display, width, height, alignItems };
    }, 
  };

  return (
    <StyledSelect
      name={name}
      value={value}
      options={options}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      styles={customStyles as StylesConfig}
      components={{
        IndicatorSeparator: () => null,
      }}
      onChange={(selectedOption: any) => onChange(selectedOption)}
    />
  );
};

export default SelectDropdown;
