import { useContext } from 'react'
import Divider from '../../components/divider/Divider'
import {
  WDReadModeTitle,
  WDStyledCancelButtonLabel,
  WDStyledToastLabel,
  WDStyledWidgetSubHeading,
  WDSubHeading,
  WDSubHeadingLabel,
  WDSubLabel,
  WDSubValue,
} from '../../components/ui/WDTypography'
import { InvestmentContext } from '../../services/investmentContext/InvestmentContext'
import { COLORS } from '../../theme/Colors'
import { DividerWrapper2, FlexItem2, IconWrapper, LabelWrap, LabelWrapper, Row, Row4, SubHeadingWrapper, ValueWrapper } from './styles'


export const InvestmentView = () => {
  const investmentFields = useContext(InvestmentContext)
  const investmentValues = investmentFields?.investmentDetails
  const InvestmentDetails = {
    Income: investmentValues?.investObjIncome,
    Growth: investmentValues?.investObjGrowth,
    Speculation: investmentValues?.investObjSpeculation,
    Trading: investmentValues?.investObjTrading,
  }
  const InvestmentDetailsLiq = {
    "0 to 5 years": investmentValues?.liqNeedTimeFrm1,
    "5 to years": investmentValues?.liqNeedTimeFrm2,
    "Over 10 years": investmentValues?.liqNeedTimeFrm3,
  }
  const InvestmentDetailsAll = {
    'Investment Allocation': investmentValues?.investAllocationResponse,
    'Investment Time Horizon': investmentValues?.investTimeHorizonResponse,
  }

  const investment_key = Object.keys(InvestmentDetails)
  const investment_value = Object.values(InvestmentDetails)
  const Investment_Length = ['1', '2', '3', '4']
  const investmentLiq_key = Object.keys(InvestmentDetailsLiq)
  const investmentLiq_value = Object.values(InvestmentDetailsLiq)
  const Investment_LengthLiq = ['1', '2', '3']
  const investmentAll_Key = Object.keys(InvestmentDetailsAll)
  const investmentAll_value = Object.values(InvestmentDetailsAll)
  const Investment_LengthAll = ['1', '2']
  return (
    <>

      <SubHeadingWrapper>
        <WDSubHeadingLabel>{'Investment Objective(s)'}</WDSubHeadingLabel>
      </SubHeadingWrapper>
      <Row>
        {Investment_Length.map((item, index) => {
          return (
            <FlexItem2 key={index}>
              <LabelWrap>
                <WDReadModeTitle>{investment_key[index]}</WDReadModeTitle>
              </LabelWrap>
              <LabelWrap>
                <WDSubValue>
                  {investment_value[index]}
                </WDSubValue>
              </LabelWrap>
            </FlexItem2>
          )
        })}
      </Row>
      <DividerWrapper2>
        <Divider
          bgColor={COLORS.Background.Border}
          horizontal={true}
          thickness="1px"
        />
      </DividerWrapper2>
      <SubHeadingWrapper>
        <WDSubHeadingLabel>{'Risk Tolerance'}</WDSubHeadingLabel>
      </SubHeadingWrapper>
      <SubHeadingWrapper>
        <WDSubHeadingLabel>{'Conservative'}</WDSubHeadingLabel>
      </SubHeadingWrapper>
      <WDStyledWidgetSubHeading>
        {
          'I want to preserve my initial principal in this account, with minimal risk, even if it means this account does not generate significant income or returns and may not keep pace with inflation.'
        }
      </WDStyledWidgetSubHeading>
      <SubHeadingWrapper>
        <WDSubLabel>
          {'Do you wish to allow illiquid investments in this account.'}
        </WDSubLabel>
      </SubHeadingWrapper>
      <WDSubValue>{investmentValues?.allowIlliquidInvestments}</WDSubValue>
      <DividerWrapper2>
        <Divider
          bgColor={COLORS.Background.Border}
          horizontal={true}
          thickness="1px"
        />
      </DividerWrapper2>
      <Row>
        {Investment_LengthAll.map((item, index) => {
          return (
            <FlexItem2 key={index}>
              <LabelWrap>
                <WDReadModeTitle>{investmentAll_Key[index]}</WDReadModeTitle>
              </LabelWrap>
              <LabelWrap>
                <WDSubValue>{investmentAll_value[index]}</WDSubValue>
              </LabelWrap>
            </FlexItem2>
          )
        })}
      </Row>
      <SubHeadingWrapper>
        <WDStyledCancelButtonLabel>{'Liquidity Needs'}</WDStyledCancelButtonLabel>
        <LabelWrapper>
          <WDStyledToastLabel>
            {'(percent of portfolio you anticipate withdrawing)'}
          </WDStyledToastLabel>
        </LabelWrapper>
      </SubHeadingWrapper>
      <Row>
        {Investment_LengthLiq.map((item, index) => {
          return (
            <FlexItem2 key={index}>
              <LabelWrap>
                <WDReadModeTitle>{investmentLiq_key[index]}</WDReadModeTitle>
              </LabelWrap>
              <LabelWrap>
                <WDSubValue>{investmentLiq_value[index]}</WDSubValue>
              </LabelWrap>
            </FlexItem2>
          )
        })}
      </Row>
      <DividerWrapper2>
        <Divider
          bgColor={COLORS.Background.Border}
          horizontal={true}
          thickness="1px"
        />
      </DividerWrapper2>
      <SubHeadingWrapper>
        <WDSubHeading>
          {"Dividends and Interest Payments"}
        </WDSubHeading>
      </SubHeadingWrapper>
      <LabelWrap>
        <WDSubValue>{investmentValues?.dividendandInstPayResponse}</WDSubValue>
      </LabelWrap>
    </>
  )
}
